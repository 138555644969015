import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import api from 'api'
import { useAuth } from 'auth/auth'
import { DealStatusesActive } from 'pages/p2p/Order/OrderAndDealData'
import { USER_VERIFICATION_STATUS } from 'pages/verification/Verification/VerificationData'
const signalR = require('@microsoft/signalr')

let ico01 = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'>
      <line x1='12.5' y1='-2.18557e-08' x2='12.5' y2='25' />
      <line x1='4.37114e-08' y1='12.5' x2='25' y2='12.5' />
    </svg>
  ),
  ico02 = (
    <svg width='19' height='19' viewBox='0 0 19 19' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.49023 9.5C4.49023 12.2609 6.7391 14.5098 9.5 14.5098C12.2609 14.5098 14.5098 12.2609 14.5098 9.5C14.5098 6.7391 12.2609 4.49023 9.5 4.49023C6.7391 4.49023 4.49023 6.7391 4.49023 9.5ZM12.1162 8.77633L8.94336 11.9604L6.88383 9.88965L7.66309 9.11039L8.94336 10.3795L11.337 7.99707L12.1162 8.77633Z' />
      <path d='M9.5 19C14.7213 19 19 14.7213 19 9.5H16.7734C16.7734 13.4856 13.4856 16.7363 9.5 16.7363C5.51442 16.7363 2.26367 13.4856 2.26367 9.5C2.26367 5.51442 5.51442 2.26367 9.5 2.26367C11.1254 2.26367 12.6283 2.79801 13.8307 3.71094L12.9846 4.55703L16.9144 5.33633L16.1352 1.40641L15.4227 2.11891C13.7973 0.816406 11.7377 0 9.5 0C4.27871 0 0 4.27871 0 9.5C0 14.7213 4.27871 19 9.5 19Z' />
    </svg>
  ),
  ico03 = (
    <svg width='18' height='19' viewBox='0 0 18 19' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.9091 2.19231H18V19H0V2.19231H4.09091V3.65385H13.9091V2.19231ZM3.27273 10.5H14.7273V8.03846H3.27273V10.5ZM3.27273 15.3462H14.7273V12.8846H3.27273V15.3462ZM5.72727 2.19231V0H12.2727V2.19231H5.72727Z' />
    </svg>
  ),
  ico04 = (
    <svg width='6' height='19' viewBox='0 0 6 19' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='14.5' width='5' height='4' rx='0.5' />
      <rect x='0.5' y='7.5' width='5' height='4' rx='0.5' />
      <rect x='0.5' y='0.5' width='5' height='4' rx='0.5' />
    </svg>
  )
const linksRight = [
  ['/posting', ico01, 'Cross', 'PublishAnAd'],
  ['/deals', ico02, 'Deals', 'Deals'],
  ['/orders', ico03, 'P2PAds', 'P2PAds'],
  ['/user', ico04, 'User', 'User'],
]

export default function P2pHeader() {
  const { t } = useTranslation()
  const auth = useAuth()
  const [signalRTimerId, setSignalRTimerId] = useState(null)
  const [activeDealsCount, setActiveDealsCount] = useState(null)

  const configureSignalR = () => {
    const { token_type, access_token } = auth.token || {}
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ApiUrl}/sr/p2p`, { accessTokenFactory: () => access_token })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    hubConnection
      .start({ transport: 'longPolling' })
      .then(() => {
        hubConnection.on('P2PDeal', (data) => {
          if (!location.pathname.toLowerCase().includes('/p2p')) {
            disconnectSignalR() // signalR has stuck, disconnect
            console.log('P2pNotification SignalR has stuck, disconnect')
            return
          }
          if (auth.isSignedIn) {
            api.fetchP2PUserDealsAll(DealStatusesActive).then((data) => {
              setActiveDealsCount(data.length)
            })
          }
        })
        hubConnection.on('P2PChat', () => {})
        hubConnection.on('P2PSellerDealCancelDispute', () => {})

        const keepAlivePeriod = 60 * 1000 // 1 minute
        const timerId = setInterval(function () {
          hubConnection.invoke('KeepAlive', '').catch((err) => console.log('KeepAlive Error: ' + err))
        }, keepAlivePeriod)
        setSignalRTimerId(timerId)
      })
      .catch((err) => {
        console.log('configureSignalR error', err)
      })
  }

  const disconnectSignalR = () => {
    if (signalRTimerId) {
      clearInterval(signalRTimerId)
      setSignalRTimerId(null)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      configureSignalR()
    }, 2000)

    return () => {
      disconnectSignalR()
    }
  }, [])

  useEffect(() => {
    if (auth.isSignedIn) {
      api.fetchP2PUserDealsAll(DealStatusesActive).then((data) => {
        setActiveDealsCount(data.length)
      })
    }
  }, [])

  return (
    <div className='p2p_header'>
      <div className='container'>
        <div className='header__layout'>
          <div className='header__logo'>
            <NavLink className='header__link' to={`/${i18n.language}/PP`} exact={true}>
              <span className='header__text'>PP</span>
            </NavLink>
          </div>
          <div className='header__menu menu'>
            <div className='menu__list'>
              {linksRight.map((element, index) => {
                return (
                  <div key={index} className='menu__item'>
                    <NavLink to={`/${i18n.language}/PP${element[0]}`} className='menu__link' exact={true}>
                      <div className='menu__media'>{element[1]}</div>
                      <div className='menu__title title'>
                        <span className='title__text'>{t(element[3])}</span>
                      </div>
                      {element[3] === 'Deals' && activeDealsCount != null && (
                        <div className='menu__tooltip tooltip'>
                          <span className='tooltip__text'>{activeDealsCount}</span>
                        </div>
                      )}
                    </NavLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
