import { BuySellTabIndex } from './PostingData'

const InitializeStageData = (order, stageData, t) => {
  if (!order) {
    return {
      isEnableSelection: true, //Allowed when creating a new order and disabled when editing an order
      currentStageIndex: stageData.currentStageIndex || 0,
      selectedBuySellTabIndex: stageData.selectedBuySellTabIndex || BuySellTabIndex.Buy,
      currencyPairs: stageData.currencyPairs || [],
      selectedPair: stageData.selectedPair || null,
      selectedFirstAsset: stageData.selectedFirstAsset || 'USDT',
      selectedSecondAsset: stageData.selectedSecondAsset || '',
      userSelectedTradeRate: stageData.userSelectedTradeRate || 0,
      availibleBalances: stageData.availibleBalances || [],
      balanceOfAssetFrom: stageData.balanceOfAssetFrom || null,
      balanceOfAssetTo: stageData.balanceOfAssetTo || null,
      selectedPaymentRequisites: stageData.selectedPaymentRequisites || [],
      limitMin: stageData.limitMin || 0,
      limitMax: stageData.limitMax || 2000,
      selectedPaymentDuration: stageData.selectedPaymentDuration || 30,
      amountOfSelectedFirstAsset: stageData.amountOfSelectedFirstAsset || 0,
      amountOfSelectedSecondAsset: stageData.amountOfSelectedSecondAsset || 0,
      commission: stageData.commission || null,
      totalAmountWithCommission: stageData.totalAmountWithCommission || 0,
      isKYCPassed: stageData.isKYCPassed || false,
      selectedMinDaysSinceRegistration: stageData.selectedMinDaysSinceRegistration || 0,
      selectedMinOrdersRequired: stageData.selectedMinOrdersRequired || 0,
      selectedMinOrderCompletionRate: stageData.selectedMinOrderCompletionRate || 0,
      termsTextarea: stageData.termsTextarea || '',
      isPaymentMethodsModalOpen: stageData.isPaymentMethodsModalOpen || false,
    }
  }

  return {
    isEnableSelection: false,
    currentStageIndex: 0,
    selectedBuySellTabIndex: order.isBuyingOnExchange ? BuySellTabIndex.Buy : BuySellTabIndex.Sell,
    currencyPairs: [],
    selectedPair: null,
    selectedFirstAsset: order.isBuyingOnExchange ? order.exchangeCurrencyCode : order.paymentCurrencyCode,
    selectedSecondAsset: order.isBuyingOnExchange ? order.paymentCurrencyCode : order.exchangeCurrencyCode,
    userSelectedTradeRate: order.price,
    availibleBalances: [],
    balanceOfAssetFrom: null,
    balanceOfAssetTo: null,
    selectedPaymentRequisites: order?.requisites.map((method) => ({ ...method, selected: true })) || [],
    limitMin: order.minDealAmount,
    limitMax: order.maxDealAmount,
    selectedPaymentDuration: order.maxTimeMinutes,
    amountOfSelectedFirstAsset: order.totalExchangeAmount,
    amountOfSelectedSecondAsset: order.totalExchangeAmount * order.price,
    commission: null,
    totalAmountWithCommission: 0,
    isKYCPassed: order.isKYCPassed,
    selectedMinDaysSinceRegistration: order.minDaysSinceRegistration,
    selectedMinOrdersRequired: order.minOrdersRequired,
    selectedMinOrderCompletionRate: order.minOrderCompletionRate,
    termsTextarea: order.description,
    isPaymentMethodsModalOpen: false,
  }
}
const MapToCreateOrderModel = (stageData) => {
  console.log(stageData)
  return {
    IsBuyingOnExchange: stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy,
    ExchangeCurrencyCode: stageData.selectedPair?.currencyFrom?.code,
    PaymentCurrencyCode: stageData.selectedPair?.currencyTo?.code,
    Price: stageData.userSelectedTradeRate,
    TotalExchangeAmount: stageData.amountOfSelectedFirstAsset,
    MinDealAmount: stageData.limitMin,
    MaxDealAmount: stageData.limitMax,
    Description: stageData.termsTextarea,
    MaxTimeMinutes: stageData.selectedPaymentDuration,
    IsKYCPassed: stageData.isKYCPassed,
    MinDaysSinceRegistration: stageData.selectedMinDaysSinceRegistration,
    MinOrdersRequired: stageData.selectedMinOrdersRequired,
    MinOrderCompletionRate: stageData.selectedMinOrderCompletionRate,
    RequisiteIds: stageData.selectedPaymentRequisites.map((pm) => pm.id),
  }
}
export { InitializeStageData, MapToCreateOrderModel }
