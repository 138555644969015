import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import arrow from './images/arrow.png'
import { useTranslation } from 'react-i18next'
const navigation = [
  {
    title: 'MainPages',
    list: [
      {
        text: 'Assets',
        link: '/finance',
      },
      {
        text: 'PP',
        link: '/PP',
      },
    ],
  },
]

export default function FooterNavigation() {
  const { t } = useTranslation()
  const [navIndex, setNavIndex] = useState(false)
  return (
    <nav className='footer__nav nav'>
      <div className='nav__groups'>
        {navigation.map((group, i) => (
          <div className={`nav__group ${navIndex === i ? 'nav__group_open' : ''}`} key={i}>
            <div className='nav__header'>
              <div className='nav__title'>
                <span className='title__text h4'>{t(group.title)}</span>
              </div>
              <div className='nav__action' onClick={() => (navIndex === i ? setNavIndex(false) : setNavIndex(i))}>
                <img src={arrow} alt='image description' />
              </div>
            </div>
            <div className='nav__main'>
              <ul className='nav__list'>
                {group.list.map((item, index) => (
                  <li className='nav__item' key={index}>
                    <NavLink to={`/${i18n.language}${item.link}`} className='nav__link'>
                      {t(item.text)}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </nav>
  )
}
