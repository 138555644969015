import React from 'react'
export default function FooterCopyright() {
  const copyrightText = '2025 ©pp.h2k.me'
  return (
    <div className='footer__copyright'>
      <div className='wysiwyg'>
        <p>{copyrightText}</p>
      </div>
    </div>
  )
}
