import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from 'api'
import Button from 'components/form/Button/Button'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import { useAuth } from 'auth/auth'
import ModalBuySell from './ModalBuySell'
import { ORDER_STATUS } from '../Order/OrderAndDealData'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'
import userModalState from '../Progress/Modals/useModalState'
import PassVerificationModal from './PassVerificationModal'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'
import { useVerificationCheck } from '../User/useVerificationCheck'

export default function Home({
  selectedExchangeCurrency,
  setSelectedExchangeCurrency,
  selectedPaymentCurrency,
  setSelectedPaymentCurrency,
}) {
  const auth = useAuth()
  const { t } = useTranslation()
  const history = useHistory()

  const [paymentSystemsList, setPaymentSystemsList] = useState(null)
  const [paymentSystemsForSelectedCurrency, setPaymentSystemsForSelectedCurrency] = useState([])
  const [loadedPaymentSystemsForCurrencies, setLoadedPaymentSystemsForCurrencies] = useState([]) // [{ currencyCode: null, paymentSystems: [] },]
  const [paymentSystemId, setPaymentSystemId] = useState(null)

  const orderSides = ['Buy', 'Sell']
  const [orderDirectionIndex, setOrderDirectionIndex] = useState(0)

  // const exchangeCurrencies = ['USDT', 'USD', 'BTC', 'ETH', 'USDC']
  const [exchangeCurrencies, setExchangeCurrencies] = useState([])
  const [paymentCurrencies, setPaymentCurrencies] = useState([])

  const [dealPaymentAmount, setDealPaymentAmount] = useState(null)
  const [orders, setOrders] = useState([])
  const [activeOrder, setActiveOrder] = useState(null)

  const [balances, setBalances] = useState(null)

  const isDealUserBuy = orderDirectionIndex === 0
  const [timer, setTimer] = useState(null)

  const [modalBuySellActive, setModalBuySellActive] = useState(false)

  const [buySellBtnDisabled, setBuySellBtnDisabled] = useState(false)
  const [currencyPairs, setCurrencyPairs] = useState(null)

  const loadOrders = (ignore = false) => {
    api
      .fetchP2POrders(
        selectedExchangeCurrency,
        selectedPaymentCurrency,
        paymentSystemId,
        !isDealUserBuy,
        null,
        ORDER_STATUS.Active,
        null,
        null,
        dealPaymentAmount === '0' ? null : dealPaymentAmount,
        auth.isSignedIn ? true : false,
      )
      .then((allOrders) => {
        if (ignore) return
        const sortedByPrice = allOrders.sort((a, b) => {
          if (isDealUserBuy) {
            return a.price - b.price
          }
          return b.price - a.price
        })

        setOrders(sortedByPrice)
      })
      .catch((error) => {
        if (ignore) return
        console.error('Error loading orders', error)
      })
  }

  const openBuySellModal = (order) => {
    setActiveOrder(order)
    setModalBuySellActive(true)
  }

  // initial load
  useEffect(() => {
    if (auth.isLoading) return

    api.fetchCurrencyPairs().then((currencyPairs) => {
      setCurrencyPairs(currencyPairs)
      setPaymentCurrencies(Array.from(new Set(currencyPairs.map((pair) => pair.currencyTo.code))))
    })

    // api.fetchP2PPaymentSystems().then((data) => {
    //   setPaymentSystemsList(data)
    // })

    api.fetchExchangeCurrencies().then((data) => {
      setExchangeCurrencies(data)
    })

    if (auth.isSignedIn) {
      api.fetchBalance().then((data) => {
        setBalances(data.balances)
      })
    }
  }, [])

  // load payment systems list when fiat currency changes
  useEffect(() => {
    let ignore = false
    if (!selectedPaymentCurrency) return
    const existingCurrencyPaymentSystems = loadedPaymentSystemsForCurrencies.find(
      (pm) => pm.currencyCode === selectedPaymentCurrency,
    )

    if (existingCurrencyPaymentSystems) {
      setPaymentSystemsForSelectedCurrency(existingCurrencyPaymentSystems.paymentSystems)
      return
    }

    api
      .fetchP2PPaymentSystems(selectedPaymentCurrency)
      .then((data) => {
        if (ignore) return
        setLoadedPaymentSystemsForCurrencies((prev) => [
          ...prev,
          { currencyCode: selectedPaymentCurrency, paymentSystems: data },
        ])
        setPaymentSystemsForSelectedCurrency(data)
      })
      .catch((error) => {
        console.error('Error fetching payment systems:', error)
      })

    return () => {
      ignore = true
    }
  }, [selectedPaymentCurrency, loadedPaymentSystemsForCurrencies])

  //reload orders on filters change
  useEffect(() => {
    let ignore = false

    loadOrders(ignore)
    return () => {
      ignore = true
    }
  }, [selectedExchangeCurrency, selectedPaymentCurrency, paymentSystemId, orderDirectionIndex])

  useEffect(() => {
    let ignore = false
    if (!dealPaymentAmount) return
    if (timer) clearTimeout(timer)

    const newTimer = setTimeout(() => {
      loadOrders(ignore)
    }, 1 * 1000)

    setTimer(newTimer)

    return () => {
      if (timer) clearTimeout(timer)
      setTimer(null)
      ignore = true
    }
  }, [dealPaymentAmount])

  return (
    <React.Fragment>
      <ModalBuySell
        opened={modalBuySellActive}
        setOpened={(value) => {
          setModalBuySellActive(value)
        }}
        isDealUserBuy={isDealUserBuy}
        order={activeOrder}
        balances={balances}
        currencyPairs={currencyPairs}
      />
      <div className='section'>
        <div className='section__main'>
          <div className='container'>
            <div className='p2p__filter'>
              <div className='row'>
                <div className='col col_4 col_tab-5 col_mob-12'>
                  <div className=' tabs tabs_second mb-0'>
                    <div className=' tabs__header mb-m-auto'>
                      <div className=' tabs__list w-m-100'>
                        {orderSides.map((element, index) => {
                          return (
                            <div
                              className={`tabs__item ${
                                element === orderSides[orderDirectionIndex] && 'tabs__item_active'
                              } ${element === 'Buy' && 'tabs__item_buy'} ${element === 'Sell' && 'tabs__item_sell'}`}
                              onClick={() => setOrderDirectionIndex(index)}
                              key={index}
                            >
                              <span className='tabs__title'>{t(element)}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_8 col_tab-7 col_mob-12'>
                  <div className='formular'>
                    <div className='form-items_exhange_curency'>
                      {exchangeCurrencies
                        .sort((a, b) => a.ordering - b.ordering)
                        .map((item, index) => (
                          <div
                            key={item.currencyCode}
                            className='form-item_exhange_curency'
                            onClick={() => setSelectedExchangeCurrency(item.currencyCode)}
                          >
                            <div className='form-item__main'>
                              <div className='form-item__field'>
                                <div className='form-item__icocheck icocheck'>
                                  <label className='icocheck__label'>
                                    <input
                                      className='icocheck__input'
                                      type='radio'
                                      name='radio'
                                      checked={selectedExchangeCurrency === item.currencyCode}
                                      readOnly={true}
                                    />
                                    <div className='icocheck__layout'>
                                      <div className='icocheck__media'>
                                        <CurrencyIcon currencyCode={item.currencyCode} />
                                      </div>
                                      <div className='icocheck__title'>
                                        <span className='icocheck__text'>{item.currencyCode}</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='p2p__filter m-m-0'>
              <div className='row'>
                <div className='col col_2 col_tab-2 col_mob-6'>
                  <div className='form-item'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('Volume')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Input
                          after={selectedPaymentCurrency}
                          value={!dealPaymentAmount || dealPaymentAmount === '0' ? '' : dealPaymentAmount}
                          placeholder={'4000'}
                          pattern={'[0-9]+([.,][0-9]{1,2})?'}
                          validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                          inputCallback={(value) => setDealPaymentAmount(value === '' ? '0' : value)}
                          addClasses={['w-100']}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_2 col_tab-3 col_mob-6'>
                  <div className='form-item'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('PaymentActive')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Select
                          list={paymentCurrencies}
                          value={selectedPaymentCurrency || ''}
                          addClasses={['w-100']}
                          filter={true}
                          image={true}
                          multiple={false}
                          selectCallback={(value) => {
                            setSelectedPaymentCurrency(value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_2 col_tab-3 col_mob-6'>
                  <div className='form-item'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('P2pPaymentSystem')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Select
                          list={['', ...paymentSystemsForSelectedCurrency.map((item) => item.name)]}
                          value={paymentSystemsForSelectedCurrency.find((s) => s.id === paymentSystemId)?.name}
                          addClasses={['w-100']}
                          filter={true}
                          multiple={false}
                          localizeOptions={false}
                          selectCallback={(value) => {
                            const paymentSystem = paymentSystemsForSelectedCurrency.find((s) => s.name === value)
                            setPaymentSystemId(paymentSystem?.id)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_6 col_tab-4 col_mob-6'>
                  <div className='form-items'>
                    <div className='form-item ml-auto'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Button
                            buttonCallback={() => {
                              loadOrders()
                            }}
                            addClasses={['btn btn_secondary-padding w-100 btn_m-circly']}
                            text={t('Refresh')}
                            after={true}
                            ico={
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                              >
                                <path
                                  d='M8 16C5.76667 16 3.875 15.225 2.325 13.675C0.775 12.125 0 10.2333 0 8C0 5.76667 0.775 3.875 2.325 2.325C3.875 0.775 5.76667 0 8 0C9.15 0 10.25 0.237333 11.3 0.712C12.35 1.18667 13.25 1.866 14 2.75V0H16V7H9V5H13.2C12.6667 4.06667 11.9373 3.33333 11.012 2.8C10.0867 2.26667 9.08267 2 8 2C6.33333 2 4.91667 2.58333 3.75 3.75C2.58333 4.91667 2 6.33333 2 8C2 9.66667 2.58333 11.0833 3.75 12.25C4.91667 13.4167 6.33333 14 8 14C9.28333 14 10.4417 13.6333 11.475 12.9C12.5083 12.1667 13.2333 11.2 13.65 10H15.75C15.2833 11.7667 14.3333 13.2083 12.9 14.325C11.4667 15.4417 9.83333 16 8 16Z'
                                  fill='white'
                                />
                              </svg>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='p2p__table table'>
              <div className='table__head'>
                <div className='table__list'>
                  <div className='table__item'>
                    <span className='table__text'>{t('Makers')}</span>
                  </div>
                  <div className='table__item'>
                    <span className='table__text'>{t('Price')}</span>
                  </div>
                  <div className='table__item'>
                    <span className='table__text'>
                      {t('Limits')}/{t('Available')}
                    </span>
                  </div>
                  <div className='table__item'>
                    <span className='table__text'>{t('P2pPaymentSystem')}</span>
                  </div>
                  <div className='table__item'>
                    <span className='table__text'>{t('Action')}</span>
                  </div>
                </div>
              </div>
              <div></div>
              <div className='table__layout'>
                {orders && orders.length > 0 ? (
                  orders.map((order, index) => (
                    <TableRow
                      key={index}
                      order={order}
                      openBuySellModal={openBuySellModal}
                      history={history}
                      buySellBtnDisabled={buySellBtnDisabled}
                    />
                  ))
                ) : (
                  <div className='empty_space'>{t('P2PNoOrdersAvailable')}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const TableRow = ({ order, status, stage, openBuySellModal, history, buySellBtnDisabled }) => {
  const { t } = useTranslation()
  const { modals, openModal, closeModal } = userModalState()
  const { goToVerificationPage, isVerified } = useVerificationCheck()
  const isDealUserBuy = !order.isBuyingOnExchange

  return (
    <React.Fragment>
      <div className='table__list'>
        <div className='table__preview'>
          <div className='table__item home__item'>
            <div className='table__header'>
              <div className='header__avatar avatar'>
                <span className='avatar__text h2'>{order.userNickname?.charAt(0) || 'U'}</span>
              </div>
              <div className='header__content'>
                <div className='header__title title'>
                  <span className='title__text h2 colorQuartyty500'>{order.userNickname}</span>
                </div>
                <div className='header__subtitle subtitle'>
                  <span className='subtitle__text colorPrimary300 h4'>
                    {order?.userInfo?.completedRecentDealsCount} {t('Deal')}
                  </span>
                  <span className='subtitle__text colorPrimary300 h4'>
                    {(order?.userInfo?.completedRecentDealsPercentage * 100).toFixed(2)}% {t('done')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='table__item home__item'>
            <div className='data'>
              <div className='data__list data__list_row data__list_row-only'>
                <div className='data__item'>
                  <div className='data__header'>
                    <span className='data__title'> {window.innerWidth < 768 ? `${t('Price')}: ` : ''} </span>
                  </div>
                  <div className='data__main'>
                    <span className='data__text'>
                      {order.price} {order.paymentCurrencyCode}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='table__item home__item'>
            <div className='data'>
              <div className='data__list data__list_row data__list_row-only'>
                <div className='data__item'>
                  <div className='data__header'>
                    <span className='data__title'>{t('Available')}:</span>
                  </div>
                  <div className='data__main'>
                    <span className='data__text'>
                      {(order.totalExchangeAmount - order.filledExchangeAmount).toFixed(2)} {order.exchangeCurrencyCode}
                    </span>
                  </div>
                </div>
                <div className='data__item home__item'>
                  <div className='data__header'>
                    <span className='data__title'>{t('Limit')}:</span>
                  </div>
                  <div className='data__main'>
                    <span className='data__text'>
                      {order.minDealAmount} - {order.maxDealAmount} {order.exchangeCurrencyCode}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='table__item home__item'>
            <div className='data'>
              <div className='data__list data__list_row data__list_row-only'>
                <div className='data__item'>
                  <div className='data__header'>
                    <span className=' data__title'>{window.innerWidth < 768 ? `${t('P2pPaymentSystem')}: ` : ''} </span>
                  </div>
                  <div className='tags tags_secondary'>
                    <div className='tags__list '>
                      {order.requisites?.map((r, index) => (
                        <div className='tags__item' key={index}>
                          <span
                            className='tags__text'
                            style={{ backgroundColor: isDealUserBuy ? '#ebfbf7' : '#ffebf1' }}
                          >
                            {r.paymentSystem.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='table__item'>
            <div className='button_column'>
              <Button
                text={
                  !order?.userInfo?.requesterCanOpenDeal
                    ? t('P2PUnavailable')
                    : ` ${isDealUserBuy ? t('Buy') : t('Sell')} ${order.exchangeCurrencyCode}`
                }
                buttonCallback={() => {
                  if (isVerified === false) {
                    openModal('isPassVerificationModalOpen')
                    return
                  }
                  openBuySellModal(order)
                }}
                addClasses={[`btn btn_secondary-padding ${isDealUserBuy ? 'btn_secondary' : 'btn_tertiary'}`]}
                disabled={!order?.userInfo?.requesterCanOpenDeal || buySellBtnDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <PassVerificationModal
        opened={modals.isPassVerificationModalOpen}
        onClose={() => closeModal('isPassVerificationModalOpen')}
        onConfirmPassVerification={goToVerificationPage}
      />
    </React.Fragment>
  )
}
