import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import i18n from 'i18next'
import CacheBuster from 'react-cache-buster'
import { ProvideAuth, useAuth } from 'auth/auth'
import PrivateRoute from './auth/PrivateRoute'

import { isProdEnv } from 'helpers/helper.js'
import 'helpers/extensions'
import ScrollToTop from 'scrollToTop'

import BalancePage from './pages/balance/BalancePage'
import RegisterPage from './pages/register/RegisterPage'
import Page404 from 'pages/page404/Page404'
import P2pPage from 'pages/p2p/P2pPage'
import EmailPage from './pages/register/email-confirmation/EmailPage'
import AfterLoginPage from './pages/register/AfterLogin/AfterLoginPage'
import ResetPWPage from 'pages/ResetPW/ResetPWPage'
import HistoryPage from 'pages/history/HistoryPage'
import Commissions from 'pages/commissions/CommissionsPage'
import SecurityPage from 'pages/p2p/security/SecurityPage'

class App extends Component {
  render() {
    const lang = '/:locale(ru|en|tr)?'

    return (
      <CacheBuster
        currentVersion={'1.0.4'} // can't import version from package.json, security risk
        isEnabled={isProdEnv} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <ProvideAuth>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <Route path={`${lang}/commissions`} component={Commissions} />
                <PrivateRoute path={`${lang}/finance`} component={BalancePage} />
                <PrivateRoute path={`${lang}/history`} component={HistoryPage} />
                <PrivateRoute path={`${lang}/security`} component={SecurityPage} />
                {/* <Route path={`${lang}/register`} component={RegisterPage} /> */}
                <Route path={`${lang}/email-success`} component={EmailPage} />
                <Route path={`${lang}/after-login`} component={AfterLoginPage} />
                {/* <Route path={`${lang}/reset-password`} component={ResetPWPage} /> */}
                <Route path={`${lang}/PP`} component={P2pPage} />
                <Route exact path={`${lang}/`}>
                  <Redirect to={`/${i18n.language || 'en'}/PP`} />
                </Route>
                <Route component={Page404} />
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
        </ProvideAuth>
      </CacheBuster>
    )
  }
}

export default App
