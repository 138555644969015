export const GetOpenOrderLimitKey = (key, directionIndex = 0) => {
  switch (key) {
    case 'canOpenNewOrderAtSelectedTradingPair':
      return `P2PCanOpenNewOrderAtSelectedTradingPair_${directionIndex === 0 ? 'Buy' : 'Sell'}`
    case 'canOpenOrderWithSelectedPriceAndRequisites':
      return 'P2PCanOpenOrderWithSelectedPriceAndRequisites'
    case 'canOpenOrderWithinAppealLimits':
      return 'P2PCanOpenOrderWithinAppealLimits'
    case 'canOpenOrderWithinTotalLimits':
      return 'P2PCanOpenOrderWithinTotalLimits'
  }
}

export const PostingStage = {
  STAGE_1_TYPE_AND_PRICE: 0,
  STAGE_2_PAYMENT_REQUISITES: 1,
  STAGE_3_ADDITIONAL_CONDITIONS: 2,
  STAGE_4_CONFIRM: 3,
}
export const NextStageChecker = (currentStageIndex, nextButtonStageDisabled) => {
  const stageToStateMap = {
    [PostingStage.STAGE_1_TYPE_AND_PRICE]: nextButtonStageDisabled.stage1,
    [PostingStage.STAGE_2_PAYMENT_REQUISITES]: nextButtonStageDisabled.stage2 || nextButtonStageDisabled.limits,
    [PostingStage.STAGE_3_ADDITIONAL_CONDITIONS]: nextButtonStageDisabled.stage3,
    [PostingStage.STAGE_4_CONFIRM]: nextButtonStageDisabled.posting,
  }
  const result = stageToStateMap[currentStageIndex]
  return result ?? true
}

export const BuySellTabIndex = {
  Buy: 0,
  Sell: 1,
}
export const DigitType = {
  Price: 0,
  Amount: 1,
}

export const HandleExchangeAssetChange = (value, stageData) => {
  const isBuying = stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy
  const shouldCheckExhangeBalance = !isBuying || stageData.selectedPair?.currencyTo?.availableOnExchange
  if (!shouldCheckExhangeBalance) return GetRoundedValue(value, DigitType.Amount, stageData)

  const rate = stageData.userSelectedTradeRate
  const exchangeBalance = stageData.availibleBalances.find(
    (balance) => balance.currencyCode === stageData.selectedFirstAsset,
  )
  const paymentBalance = stageData.availibleBalances.find(
    (balance) => balance.currencyCode === stageData.selectedSecondAsset,
  )
  const maxValueByRate = isBuying ? paymentBalance / rate : exchangeBalance

  const exceedsBalance = shouldCheckExhangeBalance && maxValueByRate && value > maxValueByRate
  const maxValue = exceedsBalance ? maxValueByRate : value

  const finalValue = GetRoundedValue(maxValue, DigitType.Amount, stageData)
  return finalValue
}

export const HandlePaymentAssetChange = (value, stageData) => {
  const isBuying = stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy
  const shouldCheckPaymentBalance = isBuying && stageData.selectedPair?.currencyTo?.availableOnExchange
  if (!shouldCheckPaymentBalance) return GetRoundedValue(value, DigitType.Amount, stageData)

  const rate = stageData.userSelectedTradeRate
  const exchangeBalance = stageData.availibleBalances.find(
    (balance) => balance.currencyCode === stageData.selectedFirstAsset,
  )
  const paymentBalance = stageData.availibleBalances.find(
    (balance) => balance.currencyCode === stageData.selectedSecondAsset,
  )
  const maxValueByRate = isBuying ? paymentBalance : exchangeBalance * rate

  const exceedsBalance = shouldCheckPaymentBalance && maxValueByRate && value / rate > maxValueByRate
  const maxValue = exceedsBalance ? maxValueByRate * rate : value

  const finalValue = GetRoundedValue(maxValue, DigitType.Amount, stageData)
  return finalValue
}

export const GetRoundedValue = (value, digitType, stageData) => {
  if (isFinite(value) && !isNaN(value)) {
    const [integerPart, decimalPart] = value.toString().split('.')
    const digit = GetDigits(digitType, stageData)
    if (decimalPart && decimalPart.length > digit) {
      return parseFloat(value).toFixed(digit)
    }

    return value
  }

  return 0
}

const GetDigits = (digitType, stageData) => {
  if (!stageData.selectedPair || !digitType) return 4

  if (digitType === DigitType.Amount) return stageData.selectedPair.digitsAmountP2P
  else if (digitType === DigitType.Price) return stageData.selectedPair.digitsPriceP2P
  return 4
}

export const GetCommissionAmount = (amountAsset, commission) => {
  if (commission === null || amountAsset === null || amountAsset === 0) return 0
  const { percent, minimum } = commission
  let commAmount = (amountAsset * percent) / 100
  if (commAmount < minimum) commAmount = minimum
  return commAmount
}

export const FindBalance = (assetCode, stageData) => {
  if (!assetCode) return null
  return stageData.availibleBalances.find((balance) => balance.currencyCode === assetCode) || null
}
