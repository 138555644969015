import attach__ico01 from '../images/attach__ico01.png'
import attach__ico02 from '../images/attach__ico02.png'
import attach__ico03 from '../images/attach__ico03.png'
import ProgressIcons from '../ProgressIcons'
import moment from 'moment/moment'
import React from 'react'
import DownloadFile from './FileDownloader'
import { SYSTEM_MESSAGE_TYPE, SystemMessageTypeToText } from 'pages/p2p/Order/OrderAndDealData'
import FilePreview from './FilePreview'

const ChatMessage = ({ t, chatMessage, deal }) => {
  const systemMessageType = SystemMessageTypeToText[chatMessage.systemMessageType]
  if (systemMessageType !== SYSTEM_MESSAGE_TYPE.NO_SYSTEM && systemMessageType !== SYSTEM_MESSAGE_TYPE.ADMIN) {
    return null
  }
  const { isDealCreator, order } = deal || {}
  let messageClassName = 'chat__message'
  let avatarContent
  let nickname
  let isReadMessageIcon
  let attachFileIcon
  let attachFileSize
  let attachFileName

  const isSystemMessage = chatMessage.isSystemMessage
  const isUserMessage = isDealCreator ? chatMessage.isDealCreator : chatMessage.isOrderCreator
  const isOpponentMessage = isDealCreator ? chatMessage.isOrderCreator : chatMessage.isDealCreator

  if (chatMessage.chatFile) {
    attachFileName = chatMessage.chatFile.fileName

    const fileSizeKb = (chatMessage.chatFile.fileSize / 1024).toFixed(2)
    attachFileSize = `${fileSizeKb > 1024 ? (fileSizeKb / 1024).toFixed(2) + ' MB' : fileSizeKb + ' KB'}`
    attachFileIcon = selectIco(chatMessage.chatFile.extension)
  }

  switch (true) {
    case isSystemMessage:
      messageClassName += ' chat__message_tertiary message'
      avatarContent = <ProgressIcons name='customerServiceBotAvatar' />
      nickname = t('P2PCustomerService')
      isReadMessageIcon = null
      break
    case isUserMessage:
      messageClassName += ' chat__message_primary message'
      avatarContent = null
      nickname = 'You'
      isReadMessageIcon = chatMessage.wasRead
      break
    case isOpponentMessage:
      messageClassName += ' chat__message_secondary message'
      nickname = isDealCreator ? order?.userNickname : deal?.userNickname
      avatarContent = <span className='avatar__text'>{nickname?.charAt(0) || 'u'}</span>
      isReadMessageIcon = null
      break
    default:
      return null // If none of the conditions match, don't render anything
  }

  return (
    <div className={messageClassName}>
      <div className='message__layout'>
        <div className='message__header'>
          <span className='message__name'>{nickname}</span>
          <span className='message__time'>{formattedDate(chatMessage.dateCreated)}</span>
          {isReadMessageIcon && <ProgressIcons name={'messageStatusRead'} />}
        </div>
        <div className='message__main'>
          {chatMessage.chatFile ? (
            <FilePreview chatFile={chatMessage.chatFile} />
          ) : (
            <div className='message__content'>
              <div className='wysiwyg'>
                <p>{chatMessage.text}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {avatarContent && <div className='message__avatar avatar'>{avatarContent}</div>}
    </div>
  )
}

const formattedDate = (date) => {
  return moment(date).format('HH:mm:ss')
}

export { ChatMessage }

const selectIco = (extension) => {
  switch (extension.toLowerCase()) {
    case '.pdf':
      return attach__ico02
    case '.jpg':
    case '.png':
    case '.jpeg':
      return attach__ico03
    case '.mp4':
      return attach__ico01
    default:
      return ''
  }
}
