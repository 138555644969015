import Main from '../common/components/Main/Main'
import { Route } from 'react-router-dom'
import i18n from 'i18next'
import P2pHeader from './P2pHeader/P2pHeader'
import P2pNotification from './P2pNotification/P2pNotification'
import P2pBanner from './P2pBanner/P2pBanner'
import Posting from './Posting/Posting'
import Order from './Order/Order'
import Home from './Home/Home'
import User from './User/User'
import React, { useEffect, useState } from 'react'
import Progress from './Progress/Progress'
import PrivateRoute from '../../auth/PrivateRoute'
import { useAuth } from 'auth/auth'
import SellerDealCancelDisputeModal from './DealDisputes/SellerDealCancelDisputeModal'

export default function P2pPage() {
  const auth = useAuth()

  const [selectedExchangeCurrency, setSelectedExchangeCurrency] = useState('USDT')
  const [selectedPaymentCurrency, setSelectedPaymentCurrency] = useState('KZT')
  const [userPaymentRequisites, setUserPaymentRequisites] = useState(null)

  if (auth?.isLoading) {
    return (
      <div>
        <h3>Loading...</h3>
      </div>
    )
  }

  return (
    <Main>
      <div className='p2p'>
        <Route exact path={`/${i18n.language}/PP/`}>
          <P2pBanner
            selectedExchangeCurrency={selectedExchangeCurrency}
            selectedPaymentCurrency={selectedPaymentCurrency}
          />
        </Route>
        <P2pHeader />
        {auth?.isSignedIn && <SellerDealCancelDisputeModal />}
        {auth?.isSignedIn && <P2pNotification />}
        <div className='p2p__main'>
          <Route
            exact
            path={`/${i18n.language}/PP/`}
            render={(props) => (
              <Home
                {...props}
                selectedExchangeCurrency={selectedExchangeCurrency}
                setSelectedExchangeCurrency={setSelectedExchangeCurrency}
                selectedPaymentCurrency={selectedPaymentCurrency}
                setSelectedPaymentCurrency={setSelectedPaymentCurrency}
              />
            )}
          />
          <PrivateRoute
            exact
            path={`/${i18n.language}/PP/posting/:orderId?`}
            render={(props) => <Posting {...props} userPaymentRequisites={userPaymentRequisites} />}
          />
          <PrivateRoute exact path={`/${i18n.language}/PP/progress/:dealId`} component={Progress} />
          <PrivateRoute exact path={`/${i18n.language}/PP/deals`} component={Order} />
          <PrivateRoute exact path={`/${i18n.language}/PP/orders`} component={Order} />
          <PrivateRoute
            exact
            path={`/${i18n.language}/PP/user`}
            render={(props) => <User {...props} setUserPaymentRequisites={setUserPaymentRequisites} />}
          />
        </div>
      </div>
    </Main>
  )
}
