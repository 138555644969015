import { Trans, useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import Textarea from 'components/form/Textarea/Textarea'
import Icon from '../ProgressIcons'
import './styles/progress.scss'
import i18n from 'i18next'

export default function AddUserToBlackListModal({ opened, onClose, onConfirmBlock, comment, setComment }) {
  const { t } = useTranslation()

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('P2PAddUserToBlacklist')}</h2>
          <p>
            <strong>{t('P2PAreYouSureYou')}</strong>
          </p>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: 'rgba(0,208,158,.08)' }}>
          <div className='wysiwyg'>
            <p>
              <Trans
                i18nKey='P2PTheListOfBlockedUsers'
                components={{
                  a: <a href={`/${i18n.language}/PP/user`} style={{ textDecoration: 'underline', color: 'inherit' }} />,
                }}
              />
            </p>
          </div>
        </div>
        <div className='formular'>
          <FeedbackComment comment={comment} setComment={setComment} t={t} />
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100' onClick={onConfirmBlock}>
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    {' '}
                    <button className='btn btn_revert w-100' onClick={() => onClose(false)}>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

function FeedbackComment({ comment, setComment, t }) {
  return (
    <div className='form-item'>
      <div className='form-item__main'>
        <div className='form-item__field'>
          <Textarea
            placeholder={t('CommentaryVisibleOnly')}
            id={'TermsOfTheTransactionRequired'}
            name={'TermsOfTheTransactionRequired'}
            value={t(comment)}
            setValue={(value) => setComment(value)}
            addClasses={['w-100']}
          />
        </div>
      </div>
    </div>
  )
}
