import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { useTranslation } from 'react-i18next'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'
import './select.scss'

const Select = (props) => {
  const { t } = useTranslation(props.localizationFIlename ?? '')
  let localizeOptions = true
  if (props.localizeOptions === false) localizeOptions = false

  let separator = null
  if (props.separator) separator = props.separator

  const arrow = (
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'>
      <path
        d='M7.71271 1.90735e-06C8.27424 1.90735e-06 8.66441 0.266523 8.8832 0.799567C9.10199 1.33261 9.0134 1.80522 8.61741 2.21739L5.37296 5.6087C5.24817 5.73913 5.11299 5.83696 4.9674 5.90217C4.82182 5.96739 4.66584 6 4.49946 6C4.33307 6 4.17709 5.96739 4.03151 5.90217C3.88592 5.83696 3.75074 5.73913 3.62595 5.6087L0.381503 2.21739C-0.0136538 1.80435 -0.101836 1.33131 0.116956 0.798263C0.335749 0.26522 0.725498 -0.000867367 1.28621 1.90735e-06H7.71271Z'
        fill='#253920'
      />
    </svg>
  )
  const dropdownTemplate = (option) => {
    if (option) {
      if (separator && (option.includes(separator) || option.endsWith(separator))) {
        const replaceSymbols = separator.length ? separator[0] : ''
        return (
          <div className='p-dropdown-template dropdown-divider'>
            <div className='p-dropdown-title title'>
              <span className='title__text'>
                {separator} {t(option.replace(new RegExp(replaceSymbols, 'g'), '').trim())} {separator}
              </span>
            </div>
          </div>
        )
      }
      return (
        <React.Fragment>
          {option && (
            <div className='p-dropdown-template'>
              {props.image && !option.includes(separator) && (
                <div className='p-dropdown-media'>
                  <CurrencyIcon currencyCode={option} />
                </div>
              )}
              {props.tags && (
                <div className='p-dropdown-tags tags tags_secondary'>
                  <div className='tags__list'>
                    <div className='tags__item'>
                      <span className='tags__text' style={{ backgroundColor: `${props.tagsColor}` }}>
                        Tinkoff
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className='p-dropdown-title title'>
                <span className='title__text'>{localizeOptions ? t(option) : option}</span>
              </div>
            </div>
          )}
        </React.Fragment>
      )
    }
    return <span>{props.placeholder}</span>
  }
  const dropdownTemplateMethod = (option) => {
    if (option) {
      return (
        <React.Fragment>
          {option && (
            <div className={`p-dropdown-template ${option.selected === true ? 'p-dropdown-template_active' : ''}`}>
              {option.img && (
                <div className='p-dropdown-media'>
                  <CurrencyIcon currencyCode={option.img} />
                </div>
              )}
              <div className='p-dropdown-title title'>
                <span className='title__text'>{t(option.title)}</span>
              </div>
            </div>
          )}
        </React.Fragment>
      )
    }
    return <span>{props.placeholder}</span>
  }
  if (props.multiple === true) {
    return (
      <MultiSelect
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.selectCallback(e.value)}
        options={props.list}
        filter={props.filter}
        className={props.addClasses}
        valueTemplate={dropdownTemplate}
        itemTemplate={dropdownTemplate}
        disabled={props.disabled}
        dropdownIcon={(options) => arrow}
      />
    )
  } else if (props.method === true) {
    return (
      <MultiSelect
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.selectCallback(e.value)}
        options={props.list}
        filter={props.filter}
        className={props.addClasses}
        valueTemplate={dropdownTemplateMethod}
        itemTemplate={dropdownTemplateMethod}
        disabled={props.disabled}
        dropdownIcon={(options) => arrow}
      />
    )
  } else {
    return (
      <Dropdown
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.selectCallback(e.value)}
        options={props.list}
        filter={props.filter}
        className={props.addClasses}
        disabled={props.disabled}
        valueTemplate={dropdownTemplate}
        itemTemplate={dropdownTemplate}
        dropdownIcon={(options) => arrow}
      />
    )
  }
}
export default Select
