import { useTranslation } from 'react-i18next'
import Modal from '../../../../components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'

export default function SelectPaymentMethodsModal({
  opened,
  setOpened,
  paymentRequisites,
  selectedPaymentRequisites,
  selectedSecondAsset,
  onPaymentMethodChange,
  onSaveStageData,
}) {
  const { t } = useTranslation()
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)
  const [localSelectedPaymentRequisites, setLocalSelectedPaymentRequisites] = useState([])
  const [prevSelectedPaymentRequisites, setPrevSelectedPaymentRequisites] = useState([])

  const history = useHistory()
  const goToUserPage = () => {
    setOpened(false)
    history.push({
      pathname: `/${i18n.language}/${'PP/user'}`,
    })
  }

  const handleConfirm = () => {
    onPaymentMethodChange(localSelectedPaymentRequisites)
    setOpened(false)
  }
  const handleCancel = () => {
    setLocalSelectedPaymentRequisites(selectedPaymentRequisites)
    onPaymentMethodChange(selectedPaymentRequisites)
    setOpened(false)
  }
  const handleSelectedPaymentMethodChange = (methodId) => {
    const selectedCount = localSelectedPaymentRequisites.filter((method) => method.selected).length

    const updatedLocalMethods = localSelectedPaymentRequisites.map((method) => {
      if (method.id === methodId) {
        if (method.selected || selectedCount < 5) {
          return { ...method, selected: !method.selected }
        }
        return method
      }
      return method
    })
    setLocalSelectedPaymentRequisites(updatedLocalMethods)
    onPaymentMethodChange(updatedLocalMethods.filter((method) => method.selected))

    const isAnyMethodSelected = updatedLocalMethods.some((method) => method.selected)
    const hasChanges = !areMethodsEqual(
      updatedLocalMethods.filter((method) => method.selected),
      prevSelectedPaymentRequisites,
    )
    setIsConfirmDisabled(!isAnyMethodSelected || !hasChanges)
  }

  const areMethodsEqual = (methods1, methods2) => {
    if (methods1.length !== methods2.length) return false
    return methods1.every((method1) => {
      const method2 = methods2.find((m) => m.id === method1.id)
      return method2 && method1.selected === method2.selected
    })
  }

  useEffect(() => {
    if (!paymentRequisites || paymentRequisites.length === 0) return

    const paymentRequisitesByCurrencyCode = paymentRequisites.filter(
      (method) => method.paymentMethodCurrencyCode === selectedSecondAsset,
    )

    const selectedPaymentRequisitesByCurrencyCode = selectedPaymentRequisites.filter(
      (method) => method.paymentMethodCurrencyCode === selectedSecondAsset,
    )

    const updatedPaymentRequisites = paymentRequisitesByCurrencyCode.map((method) => {
      const matchingSelectedMethod = selectedPaymentRequisitesByCurrencyCode.find(
        (selectedMethod) => selectedMethod.id === method.id,
      )

      return matchingSelectedMethod ? { ...method, ...matchingSelectedMethod } : method
    })
    setLocalSelectedPaymentRequisites(updatedPaymentRequisites)
    onPaymentMethodChange(updatedPaymentRequisites.filter((method) => method.selected))
    setPrevSelectedPaymentRequisites(updatedPaymentRequisites.filter((method) => method.selected))
  }, [])

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('ManagePaymentMethods')}</h2>
          <p>
            <strong>{t('PleaseSelectUpTo5PaymentMethods')}</strong>
          </p>
        </div>
        {Array.isArray(localSelectedPaymentRequisites) &&
          localSelectedPaymentRequisites.map((method) => (
            <div key={method.id} className='modal__methods methods'>
              <div className='methods__list modal__margin_bottom'>
                <div className='methods__item'>
                  <label className='methods__label'>
                    <div className='methods__header'>
                      <div className='methods__item' key={method.id}>
                        <Checkbox
                          type='checkbox'
                          id={method.id}
                          text={`${method?.paymentSystem?.name} (${method?.paymentMethodCurrencyCode})`}
                          checked={method.selected ?? false}
                          checkboxCallback={() => handleSelectedPaymentMethodChange(method.id)}
                        />
                      </div>
                    </div>
                    <div className='methods__main'>
                      <div className='data'>
                        <div className='data__list data__list_tertiary data__list_column'>
                          <div className='row'>
                            <div className='col col_3 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('FirstName')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.ownerName}</span>
                                </div>
                              </div>
                            </div>
                            <div className='col col_5 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('BankAccountNumber')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.cardNumber}</span>
                                </div>
                              </div>
                            </div>
                            <div className='col col_4 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('BankBranch')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.bankBranch || '--'}</span>
                                </div>
                              </div>
                            </div>
                            <div className='col col_4 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('BankName')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.bankName || '--'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className='modal__footer'>
        <div className='row'>
          <div className='col col_6 col_mob-12'>
            <div className='form-item' onClick={() => handleCancel()}>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <button className='btn btn_revert w-100'>
                    <span className='btn__text'>{t('Cancel')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col col_6 col_mob-12'>
            <div className='form-item'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <button className='btn w-100' onClick={handleConfirm} disabled={isConfirmDisabled}>
                    <span className='btn__text'>{t('Confirm')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className='col col_12 cursor-pointer'
            onClick={() => {
              onSaveStageData()
              goToUserPage()
            }}
          >
            <div className='wysiwyg colorQuartyty500 text-center'>
              <p>
                <strong>{t('P2PHeadToTheUserCenterToAddPaymentMethods')}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
