import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/layout/Modal/Modal'

const NOTIFICATION_TYPES = {
  P2POrdersEmailNotification: 'P2POrdersEmailNotification',
  P2PDealsEmailNotification: 'P2PDealsEmailNotification',
  P2PAppealsEmailNotification: 'P2PAppealsEmailNotification',
  P2PChatMessagesEmailNotification: 'P2PChatMessagesEmailNotification',
}

export default function ModalNotification({
  opened,
  setOpened,
  emailNotificationSettings,
  onChangeNotificationSettings,
}) {
  const { t } = useTranslation()

  const notification = {
    P2POrdersEmailNotification: null,
    P2PDealsEmailNotification: null,
    P2PAppealsEmailNotification: null,
    P2PChatMessagesEmailNotification: null,
  }

  const [notificationStates, setNotificationStates] = useState(notification)

  const handleChange = (type) => {
    setNotificationStates((prevStates) => {
      const updatedStates = { ...prevStates, [type]: !prevStates[type] }
      return updatedStates
    })
  }

  const handleConfirm = () => {
    const changedSettings = Object.keys(notificationStates).reduce((acc, key) => {
      const setting = emailNotificationSettings?.find((setting) => setting.type === key)
      const currentValue = setting ? setting.value.toLowerCase() === 'true' : null

      if (currentValue === null || notificationStates[key] !== currentValue) {
        acc[key] = notificationStates[key]
      }

      return acc
    }, {})

    if (Object.keys(changedSettings).length > 0) {
      onChangeNotificationSettings(changedSettings)
    }
  }

  const handleCancel = () => {
    if (!emailNotificationSettings || !emailNotificationSettings?.length) {
      handleConfirm()
    }
    setNotificationStates(notification)
  }

  useEffect(() => {
    if (emailNotificationSettings === null || !opened) return

    const getSettingValue = (type) => {
      const setting = emailNotificationSettings.find((setting) => setting.type === type)
      return setting ? setting.value.toLowerCase() === 'true' : true
    }

    setNotificationStates({
      P2POrdersEmailNotification: getSettingValue(NOTIFICATION_TYPES.P2POrdersEmailNotification),
      P2PDealsEmailNotification: getSettingValue(NOTIFICATION_TYPES.P2PDealsEmailNotification),
      P2PAppealsEmailNotification: getSettingValue(NOTIFICATION_TYPES.P2PAppealsEmailNotification),
      P2PChatMessagesEmailNotification: getSettingValue(NOTIFICATION_TYPES.P2PChatMessagesEmailNotification),
    })
  }, [emailNotificationSettings, opened])

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('SettingUpNotifications')}</h2>
        </div>
        <div className='modal__notifications notifications'>
          <div className='notifications__groups groups'>
            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('Orders')}</h3>
                  <p>{t('ToReceiveNotificationsAboutOrders')}</p>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('EmailMail')}</h3>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input
                            className='switcher__input'
                            type='checkbox'
                            name='toggle'
                            checked={notificationStates.P2POrdersEmailNotification}
                            onChange={() => handleChange('P2POrdersEmailNotification')}
                          />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('Deals')}</h3>
                  <p>{t('ToReceiveNotificationsAboutDeals')}</p>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('EmailMail')}</h3>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input
                            className='switcher__input'
                            type='checkbox'
                            name='toggle'
                            checked={notificationStates.P2PDealsEmailNotification}
                            onChange={() => handleChange('P2PDealsEmailNotification')}
                          />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('Appeals')}</h3>
                  <p>{t('ToReceiveNotificationsAboutAppeals')}</p>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('EmailMail')}</h3>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input
                            className='switcher__input'
                            type='checkbox'
                            name='toggle'
                            checked={notificationStates.P2PAppealsEmailNotification}
                            onChange={() => handleChange('P2PAppealsEmailNotification')}
                          />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('ChatMessages')}</h3>
                  <p>{t('ToReceiveNotificationsAboutChatMessages')}</p>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('EmailMail')}</h3>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input
                            className='switcher__input'
                            type='checkbox'
                            name='toggle'
                            checked={notificationStates.P2PChatMessagesEmailNotification}
                            onChange={() => handleChange('P2PChatMessagesEmailNotification')}
                          />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div
                className='form-item'
                onClick={() => {
                  handleCancel()
                  setOpened(false)
                }}
              >
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100'>
                      <span className='btn__text'>{t('Cancel')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100' onClick={handleConfirm}>
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
