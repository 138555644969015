import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Datepicker from 'components/form/Datepicker/Datepicker'
import Select from 'components/form/Select/Select'
import api from 'api'
import RenderMyOrder from './RenderMyOrder'
import RenderMyDeal from './RenderMyDeal'
import Icon from './OrderIcons'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'
import i18n from 'i18next'
import {
  DEAL_STATUS,
  DealStatusesActive,
  DealStatusNumberToTextMap,
  DealStatusTranslateTextToDealStatusText,
  ORDER_STATUS,
  TabNames,
  TabIndex,
  FilterOptions,
  GetDealAppealStatus,
  APPEAL_STATUS,
  APPEAL_TYPES,
} from './OrderAndDealData'

export default function Order() {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [currencyOptions, setCurrencyOptions] = useState([FilterOptions.P2PAllCurrency])
  const [selectedCurrency, setSelectedCurrency] = useState(FilterOptions.P2PAllCurrency)
  const [tradeOptions, setTradeOptions] = useState([FilterOptions.BuySell, FilterOptions.Buy, FilterOptions.Sell])
  const [selectedTradeOption, setSelectedTradeOption] = useState(FilterOptions.BuySell)
  const [statusOptions, setStatusOptions] = useState([FilterOptions.P2PDealStatus_AllStatuses])
  const [selectedStatus, setSelectedStatus] = useState(FilterOptions.P2PDealStatus_AllStatuses)
  const [tabIdList, setTabIdList] = useState([
    TabNames.InProcess,
    TabNames.AllDeals,
    TabNames.P2PMyAdsOnListing,
    TabNames.P2PMyAdsAll,
  ])

  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [filteredOrders, setFilteredOrders] = useState([])
  const [orders, setOrders] = useState(null)
  const [filteredDeals, setFilteredDeals] = useState([])
  const [deals, setDeals] = useState(null)
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null })

  const [selectedTabIdIndex, setSelectedTabIdIndex] = useState(() => {
    if (location.pathname.includes('/deals')) {
      return TabIndex.InProcess
    } else if (location.pathname.includes('/orders')) {
      return TabIndex.P2PMyAdsOnListing
    }

    return TabIndex.InProcess
  })

  const resetFilter = async () => {
    setSelectedCurrency(currencyOptions[0])
    setSelectedTradeOption(tradeOptions[0])
    setSelectedStatus(FilterOptions.P2PDealStatus_AllStatuses)
    setSelectedDateRange({ startDate: null, endDate: null })
  }

  const filterOrders = () => {
    if (!orders) return
    let filtered = orders

    if (selectedCurrency !== FilterOptions.P2PAllCurrency) {
      filtered = filtered.filter(
        (order) => order.exchangeCurrencyCode === selectedCurrency || order.paymentCurrencyCode === selectedCurrency,
      )
    }
    if (selectedTradeOption !== FilterOptions.BuySell) {
      const isBuying = selectedTradeOption === FilterOptions.Buy
      filtered = filtered.filter((order) => order.isBuyingOnExchange === isBuying)
    }

    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.dateCreated)
        return orderDate >= selectedDateRange.startDate && orderDate <= selectedDateRange.endDate
      })
    }
    setFilteredOrders(filtered)
  }
  const filterDeals = () => {
    if (!deals) return

    let filtered = deals

    if (selectedCurrency !== FilterOptions.P2PAllCurrency) {
      filtered = filtered.filter(
        (deal) =>
          deal.order.exchangeCurrencyCode === selectedCurrency || deal.order.paymentCurrencyCode === selectedCurrency,
      )
    }
    if (selectedTradeOption !== FilterOptions.BuySell) {
      const isBuying = selectedTradeOption === FilterOptions.Buy
      filtered = filtered.filter((deal) => deal.order.isBuyingOnExchange === isBuying)
    }
    if (selectedStatus !== FilterOptions.P2PDealStatus_AllStatuses) {
      const selectedStatusNumber = DEAL_STATUS[selectedStatus]
      filtered = filtered.filter((deal) => {
        const numberDealStatus = deal.isAppealed || deal.isAppealedAfterFinish ? GetDealAppealStatus(deal) : deal.status
        return numberDealStatus === selectedStatusNumber ? deal : null
      })
    }
    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      filtered = filtered.filter((deal) => {
        const orderDate = new Date(deal.dateCreated)
        return orderDate >= selectedDateRange.startDate && orderDate <= selectedDateRange.endDate
      })
    }
    setFilteredDeals(filtered)
  }

  const updateFiltersOrder = (orders) => {
    if (!orders) return

    const allCurrencies = orders.flatMap((order) => [order.exchangeCurrencyCode, order.paymentCurrencyCode])
    const uniqueCurrencies = Array.from(new Set(allCurrencies.filter(Boolean)))
    setCurrencyOptions([FilterOptions.P2PAllCurrency, ...uniqueCurrencies])
  }

  const updateFiltersDeal = (deals) => {
    if (!deals) return

    const allCurrencies = deals.flatMap((deal) => [deal.order.exchangeCurrencyCode, deal.order.paymentCurrencyCode])
    const uniqueCurrencies = Array.from(new Set(allCurrencies.filter(Boolean)))
    setCurrencyOptions([FilterOptions.P2PAllCurrency, ...uniqueCurrencies])

    const uniqueStatusNumbers = Array.from(
      new Set(
        deals.map((deal) => {
          const numberDealStatus =
            deal.isAppealed || deal.isAppealedAfterFinish ? GetDealAppealStatus(deal) : deal.status
          return numberDealStatus
        }),
      ),
    )
    const uniqueStatusTexts = uniqueStatusNumbers.map((statusNum) => DealStatusNumberToTextMap[statusNum] || 'Unknown')
    setStatusOptions([FilterOptions.P2PDealStatus_AllStatuses, ...uniqueStatusTexts])
  }

  const getDealWithAppeal = (appeal, deal) => {
    const dealWithAppeal = deal
    dealWithAppeal.isAppealed = appeal.appealType === APPEAL_TYPES.appealedForActiveDeal
    dealWithAppeal.isAppealedAfterFinish = appeal.appealType === APPEAL_TYPES.appealedAfterFinish
    dealWithAppeal.status = GetDealAppealStatus(deal)
    return dealWithAppeal
  }

  const loadData = (ignore = false) => {
    if (selectedTabIdIndex === TabIndex.InProcess) {
      const fetchDeals = api.fetchP2PUserDealsAll(DealStatusesActive)
      const fetchAppeals = api.fetchP2PUserAppeals(APPEAL_STATUS.InProgress, APPEAL_TYPES.appealedAfterFinish)

      Promise.all([fetchDeals, fetchAppeals])
        .then(([dealsData, appealsData]) => {
          if (ignore) return

          const appealDeals = appealsData.map((appeal) => getDealWithAppeal(appeal, appeal.deal))
          const allDeals = [...dealsData, ...appealDeals]
          const uniqueDeals = Array.from(new Map(allDeals.map((deal) => [deal.id, deal])).values())

          setDeals(uniqueDeals)
          setFilteredDeals(uniqueDeals)
          updateFiltersDeal(uniqueDeals)
        })
        .catch((error) => {
          if (ignore) return
          console.error('Error loading deals', error)
        })
    } else if (selectedTabIdIndex === TabIndex.AllDeals) {
      api
        .fetchP2PUserDealsAll()
        .then((data) => {
          if (ignore) return
          setDeals(data)
          setFilteredDeals(data)
          updateFiltersDeal(data)
        })
        .catch((error) => {
          if (ignore) return
          console.error('Error loading deals', error)
        })
    } else if (selectedTabIdIndex === TabIndex.P2PMyAdsOnListing) {
      api
        .fetchP2PUserOrders(ORDER_STATUS.Active)
        .then((data) => {
          if (ignore) return
          setOrders(data)
          setFilteredOrders(data)
          updateFiltersOrder(data)
        })
        .catch((error) => {
          if (ignore) return
          console.error('Error loading orders', error)
        })
    } else if (selectedTabIdIndex === TabIndex.P2PMyAdsAll) {
      api
        .fetchP2PUserOrders(ORDER_STATUS.ClosedCryptoUnfrozen)
        .then((data) => {
          if (ignore) return
          setOrders(data)
          setFilteredOrders(data)
          updateFiltersOrder(data)
        })
        .catch((error) => {
          if (ignore) return
          console.error('Error loading orders', error)
        })
    }
  }
  const cancelOrder = (orderId) => {
    api
      .cancelP2POrder(orderId)
      .then((data) => {
        loadData()
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
  }

  const editOrder = (order) => {
    history.push({
      pathname: `/${i18n.language}/pp/posting/${order.id}`,
      state: { order },
    })
  }

  useEffect(() => {
    let ignore = false

    loadData(ignore)
    resetFilter()
    if (selectedTabIdIndex === TabIndex.P2PMyAdsAll || selectedTabIdIndex === TabIndex.P2PMyAdsOnListing) {
      updateFiltersOrder(orders)
    } else {
      updateFiltersDeal(deals)
    }
    return () => {
      ignore = true
    }
  }, [tabIdList, selectedTabIdIndex])

  useEffect(() => {
    let ignore = false

    if (location.state && location.state.shouldUpdate) {
      selectedTabIdIndex === TabIndex.InProcess ? loadData(ignore) : setSelectedTabIdIndex(TabIndex.InProcess)

      return
    }
    return () => {
      ignore = true
    }
  }, [location.state])

  useEffect(() => {
    filterOrders()
  }, [selectedDateRange, selectedCurrency, selectedTradeOption, orders])

  useEffect(() => {
    filterDeals()
  }, [selectedDateRange, selectedCurrency, selectedTradeOption, selectedStatus, deals])

  return (
    <React.Fragment>
      <div className='section'>
        <div className='section__main'>
          <div className='container'>
            <div className=' tabs'>
              <div className=' tabs__header'>
                <div className=' tabs__list'>
                  {tabIdList.map((element, index) => {
                    return (
                      <div
                        className={`tabs__item ${element === tabIdList[selectedTabIdIndex] && 'tabs__item_active'}`}
                        onClick={() => setSelectedTabIdIndex(index)}
                        key={index}
                      >
                        <span className='tabs__title'>{t(element)}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className=' tabs__main'>
                <div className=' tabs__list'>
                  <div className=' tabs__item'>
                    <div className={`p2p__filter filter ${isFilterApplied ? 'p2p__filter_active' : ''}`}>
                      <div className='filter__formular formular'>
                        <div className='row justify-space-between row_m-revers'>
                          <div className='col col_9 col_tab-10 col_mob-12'>
                            <div className='filter__main'>
                              <div className='row'>
                                <div className='col col_3 col_mob-12'>
                                  <div className='form-item'>
                                    <div className='form-item__header'>
                                      <span className='form-item__title'>{t('Currency')}</span>
                                    </div>
                                    <div className='form-item__main'>
                                      <div className='form-item__field'>
                                        <Select
                                          list={currencyOptions}
                                          value={selectedCurrency}
                                          addClasses={['w-100']}
                                          filter={true}
                                          multiple={false}
                                          selectCallback={(value) => {
                                            setSelectedCurrency(value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col col_3 col_mob-12'>
                                  <div className='form-item'>
                                    <div className='form-item__header'>
                                      <span className='form-item__title'>{t('OrderType')}</span>
                                    </div>
                                    <div className='form-item__main'>
                                      <div className='form-item__field'>
                                        <Select
                                          list={tradeOptions}
                                          value={selectedTradeOption}
                                          addClasses={['w-100']}
                                          filter={true}
                                          multiple={false}
                                          selectCallback={(value) => {
                                            setSelectedTradeOption(value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col col_3 col_mob-12'>
                                  <div className='form-item'>
                                    <div className='form-item__header'>
                                      <span className='form-item__title'>{t('Status')}</span>
                                    </div>
                                    <div className='form-item__main'>
                                      <div className='form-item__field'>
                                        <Select
                                          disabled={
                                            tabIdList[selectedTabIdIndex] === TabNames.P2PMyAdsAll ||
                                            tabIdList[selectedTabIdIndex] === TabNames.P2PMyAdsOnListing
                                          }
                                          list={statusOptions.map((status) => t(`P2PDealStatus_${status}`))}
                                          value={
                                            selectedTabIdIndex === TabIndex.P2PMyAdsAll ||
                                            selectedTabIdIndex === TabIndex.P2PMyAdsOnListing
                                              ? t(`P2PDealStatus_${FilterOptions.P2PDealStatus_AllStatuses}`)
                                              : t(`P2PDealStatus_${selectedStatus}`)
                                          }
                                          disableded={tabIdList[selectedTabIdIndex] === TabNames.P2PMyAdsAll}
                                          addClasses={['w-100']}
                                          filter={true}
                                          multiple={false}
                                          selectCallback={(value) => {
                                            if (
                                              value === t(`P2PDealStatus_${FilterOptions.P2PDealStatus_AllStatuses}`)
                                            ) {
                                              setSelectedStatus(FilterOptions.P2PDealStatus_AllStatuses)
                                            } else {
                                              setSelectedStatus(DealStatusTranslateTextToDealStatusText(t)[value])
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col col_3 col_mob-12'>
                                  <div className='form-item'>
                                    <div className='form-item__header'>
                                      <span className='form-item__title'>{t('Date')}</span>
                                    </div>
                                    <div className='form-item__main'>
                                      <div className='form-item__field'>
                                        {
                                          <Datepicker
                                            addClasses={[' ']}
                                            selectionMode='range'
                                            DatepickerCallback={(selectedDateRange) => {
                                              setSelectedDateRange(selectedDateRange)
                                            }}
                                            dateRange={selectedDateRange}
                                          />
                                        }
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col col_3 col_tab-2 col_mob-12'>
                            <div className='filter__action action'>
                              <div className='action__list'>
                                <div
                                  className='action__item action__item_init'
                                  onClick={() => setIsFilterApplied(!isFilterApplied)}
                                >
                                  <div className='action__ico'>
                                    <Icon name='filterIco' />
                                  </div>
                                </div>
                                <div className='action__item action__item_reset' onClick={() => resetFilter()}>
                                  <div className='action__title title'>
                                    <span className='title__text' style={{ paddingRight: '0.25rem' }}>
                                      {t('ResetTheFilter')}
                                    </span>
                                  </div>
                                  <div className='action__ico'>
                                    <Icon name='closeIco' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {(selectedTabIdIndex === TabIndex.InProcess || selectedTabIdIndex === TabIndex.AllDeals) && (
                        <RenderMyDeal deals={filteredDeals} />
                      )}
                      {(selectedTabIdIndex === TabIndex.P2PMyAdsOnListing ||
                        selectedTabIdIndex === TabIndex.P2PMyAdsAll) && (
                        <RenderMyOrder
                          onCancelOrder={(orderId) => cancelOrder(orderId)}
                          onEditOrder={(order) => editOrder(order)}
                          orders={filteredOrders}
                          selectedTabIndex={selectedTabIdIndex}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
