var config = {}
const useLocalEndpoints = false

const debugAccessToken = {
  token_type: 'Bearer',
  access_token: '',
}

if (useLocalEndpoints) {
  config.ApiUrl = 'https://localhost:5102'
  config.ApiAuthUrl = 'https://localhost:5352'
  config.debugAccessToken = debugAccessToken.access_token.trim() === '' ? null : debugAccessToken
} else {
  config.ApiUrl = 'https://webapp.h2k.me'
  config.ApiAuthUrl = ''
  config.debugAccessToken = null
  config.redirectUri = 'https://pp.h2k.me/en/after-login'
}

module.exports = config
