import axios from 'axios'
import i18n from 'i18next'

import { ApiAuthUrl, ApiUrl } from 'config.js'

const convertDateToServer = (date) => date.toISOString()

const getUrl = (url) => (url.startsWith('http') ? url : `${ApiUrl}${url}`)

function errorHandler(err) {
  if (!err.response) {
    console.error('Server error.')
    throw err
  }
  if (err.response.status === 400 && err.response.data) {
    console.error('You should be authenticated.', err.response.data.message)
  } else if (err.response.status === 401) {
    console.error('You should be authenticated.')
  } else if (err.response.status === 403) {
    console.error('You should be authenticated.')
  } else {
    console.error('Server error. ' + err.response.statusText)
  }
  throw err
}

const BaseApi = {
  get: (url, options) =>
    axios
      .get(getUrl(url), options)
      .then(({ data }) => data)
      .catch(errorHandler),
  post: (url, model, config) => axios.post(getUrl(url), model, config).then(({ data }) => data),
  delete: (url) => axios.delete(getUrl(url)).catch(errorHandler),
  put: (url, model, config) =>
    axios
      .put(getUrl(url), model, config)
      .then(({ data }) => data)
      .catch(errorHandler),
}

export default {
  ApiUrl,
  ApiAuthUrl,
  errorHandler,

  // Finance
  fetchFinanceInfo: () => BaseApi.get('/api/users/finances-info'),
  fetchFinanceHistory: (onlyCash = false) => BaseApi.get(`/api/finance/history?onlyCash=${onlyCash}`),
  fetchBalance: (onlyAvailableOnExchange = true) =>
    BaseApi.get(`/api/users/balances?onlyAvailableOnExchange=${onlyAvailableOnExchange}`),
  fetchDepositInfo: (currencyCode, adapterCode) =>
    BaseApi.get(`/api/deposit-info/${currencyCode}?adapterCode=${adapterCode}`),
  fetchCommissions: () => BaseApi.get('/api/currency-pairs/commissions'),
  estimateWithdrawalCommission: (amount, currencyCode, adapterCode) =>
    BaseApi.post('/api/withdrawal/calculate-commission', { amount, currencyCode, adapterCode }),
  fetchPublicID: () => BaseApi.get('/api/finance/public-id'),
  countActiveCashOperations: () => BaseApi.get('/api/finance/cash/count'),
  createCashDeposit: (data) => BaseApi.post('/api/deposits/cash', data),
  cancelCashDeposit: (id) => BaseApi.delete(`/api/deposits/cash/${id}`),
  createWithdrawal: (model) => BaseApi.post('/api/withdrawal', model),
  sendEmailConfirmation: (model) => BaseApi.post('/api/withdrawals/confirmation/email', model),
  cancelCashWithdrawal: (id) => BaseApi.delete(`/api/withdrawals/${id}`),
  fetchGoogle2FaWithdrawalStatus: () => BaseApi.get(`/api/withdrawals/2fa/status`),
  // Trade
  fetchInstruments: () => BaseApi.get('/api/currency-pairs/price-and-volume'),
  fetchBars: (barRange, symbol, fromDate, toDate) =>
    BaseApi.get(
      `/api/marketdata/GetOHLC/${barRange}/${symbol}/` +
        `${convertDateToServer(fromDate)}/${convertDateToServer(toDate)}`,
    ),
  // Deals
  fetchDeals: (symbol) => BaseApi.get(`/api/deals/main?currencyPairCode=${symbol}`),
  fetchUserDeals: (symbol) => {
    return BaseApi.get(`/api/deals/users?currencyPairCode=${symbol}`)
  },

  // Helpdesk
  fetchHelpdeskTickets: () => BaseApi.get('/api/tickets'),
  fetchHelpdeskTicket: (id) => BaseApi.get(`/api/tickets/${id}`),
  createHelpdeskTicket: (data) => BaseApi.post('/api/tickets', data),
  addHelpdeskTicketMessage: (data) => BaseApi.post('/api/tickets/message', data),

  // ApiKeys
  fetchApiKeys: () => BaseApi.get('/api/apikeys'),
  createApiKey: (data) => BaseApi.post('/api/apikeys', data),
  deleteApiKey: (publicKey) => BaseApi.delete(`/api/apikeys/${publicKey}`),

  // Verification
  fetchUserVerificationStatus: () => BaseApi.get('/api/h2k/verification-status'),

  // Misc
  fetchNews: () => BaseApi.get('/api/news'),
  fetchNewsItem: (id) => BaseApi.get(`/api/news/${id}`),
  fetchFaqQuestions: () => BaseApi.get('/api/faq'),
  fetchImage: (id, option) => BaseApi.get(`/api/image/${id}`, option),

  //P2P
  fetchCurrencyPairs: () => BaseApi.get(`/api/p2p/currency-pairs`),
  fetchP2PPaymentSystems: (currencyCode = null) =>
    BaseApi.get(`/api/p2p/payment-systems?currencyCode=${currencyCode ?? ''}`),
  fetchExchangeCurrencies: () => BaseApi.get(`/api/p2p/exchange-currencies`),
  fetchP2PPaymentMethods: (paymentSystemId = null) =>
    BaseApi.get(`/api/p2p/payment-methods?paymentSystemId=${paymentSystemId ?? ''}`),
  //P2P Orders
  fetchP2POrders: (
    exchangeCurrencyCode = null,
    paymentCurrencyCode = null,
    paymentSystemId = null,
    isBuyingOnExchange = null,
    userId = null,
    status = null,
    dateFrom = null,
    dateTo = null,
    dealPaymentAmount = null,
    isFilterForUserId = null,
  ) =>
    BaseApi.get(
      `/api/p2p/orders?exchangeCurrencyCode=${exchangeCurrencyCode ?? ''}` +
        `&paymentCurrencyCode=${paymentCurrencyCode ?? ''}` +
        `&paymentSystemId=${paymentSystemId ?? ''}` +
        `&isBuyingOnExchange=${isBuyingOnExchange ?? ''}&userId=${userId ?? ''}&status=${status ?? ''}` +
        `&dateFrom=${dateFrom ?? ''}&dateTo=${dateTo ?? ''}&dealPaymentAmount=${dealPaymentAmount ?? ''}` +
        `&isFilterForUserId=${isFilterForUserId ?? ''}`,
    ),
  fetchP2PUserOrders: (status = null) => {
    return BaseApi.get(`/api/p2p/users/orders?status=${status ?? ''}`)
  },
  createP2POrder: (model) => {
    return BaseApi.post(`/api/p2p/orders`, model)
  },
  cloneP2POrder: (id, model) => {
    return BaseApi.post(`/api/p2p/orders/${id}/clone`, model)
  },
  cancelP2POrder: (id) => {
    return BaseApi.post(`/api/p2p/orders/${id}/cancel`)
  },

  //P2P Deals
  fetchP2PDeal: (id) => BaseApi.get(`/api/p2p/deals/${id}`),
  fetchP2PUserDeals: (statusList = []) => {
    const statusParams = statusList.map((status) => `statuses=${status}`).join('&')
    return BaseApi.get(`/api/p2p/users/deals?&${statusParams}`)
  },
  fetchP2PUserDealsAll: (statusList = []) => {
    // includes deals of orders created by user too
    const statuses = statusList.map((status) => `statuses=${status}`).join('&')
    return BaseApi.get(`/api/p2p/users/deals/all?&${statuses}`)
  },
  createP2PDeal: (deal) => BaseApi.post('/api/p2p/deals', deal),
  updateP2PDealStatus: (id, status) => BaseApi.put(`/api/p2p/deals/${id}/payment-status?dealStatus=${status}`),
  cancelP2PDeal: (id) => BaseApi.delete(`/api/p2p/deals/${id}`),

  //P2P Requisites
  fetchP2PRequisites: () => BaseApi.get(`/api/p2p/requisites`),
  createP2PRequisite: (dto) => BaseApi.post(`/api/p2p/requisites`, dto),
  updateP2PRequisite: (id, dto) => BaseApi.put(`/api/p2p/requisites/${id}`, dto),
  deleteP2PRequisite: (id) => BaseApi.delete(`/api/p2p/requisites/${id}`),

  //P2P info
  fetchP2PUserInfo: () => BaseApi.get(`/api/p2p/users/info`),
  fetchP2PUserOrderOpeningLimits: (
    isBuyingOnExchange,
    exchangeCurrencyCode,
    paymentCurrencyCode,
    price,
    requisiteIds = null,
    editableOrderId = null,
  ) => {
    let params =
      `isBuyingOnExchange=${isBuyingOnExchange}&exchangeCurrencyCode=${exchangeCurrencyCode}` +
      `&paymentCurrencyCode=${paymentCurrencyCode}&price=${price}`
    if (requisiteIds) {
      params += requisiteIds.map((id) => `&requisiteIds=${id}`).join('&')
    }
    params += editableOrderId ? `&editableOrderId=${editableOrderId}` : ''

    return BaseApi.get(`/api/p2p/users/trading-limits/order?${params}`)
  },
  fetchP2PUserDealOpeningLimits: () => BaseApi.get(`/api/p2p/users/trading-limits/deal`),

  //P2P Deal Comments
  fetchP2PDealComments: (dealId = null, isFromUser = false, isToUser = false) => {
    const params = `dealId=${dealId ?? ''}&isFromUser=${isFromUser}&isToUser=${isToUser}`
    return BaseApi.get(`/api/p2p/deal-comments?${params}`)
  },
  createP2PDealComments: (dealCommentDto) => BaseApi.post(`/api/p2p/deal-comments/`, dealCommentDto),
  updateP2PDealComments: (dealCommentDto) => BaseApi.put(`/api/p2p/deal-comments`, dealCommentDto),
  deleteP2PDealComments: (dealCommentId) => BaseApi.delete(`/api/p2p/deal-comments/${dealCommentId}`),

  //P2P UserBlocks
  fetchP2PUserBlockList: (blockedUserId = null) => {
    return BaseApi.get(`/api/p2p/user-blocks?blockedUserId=${blockedUserId ?? ''}`)
  },
  addP2PUserBlock: (userBlockCreateDto) => {
    return BaseApi.post(`/api/p2p/user-blocks`, userBlockCreateDto)
  },
  removeP2PUserBlock: (unblockedUserId) => {
    return BaseApi.delete(`/api/p2p/user-blocks?unblockedUserId=${unblockedUserId}`)
  },

  //P2P Chats
  fetchP2PMessages: (dealId) => BaseApi.get(`/api/p2p/chats?dealId=${dealId}`),
  fetchP2PMessage: (id) => BaseApi.get(`/api/p2p/chats/${id}`),
  createP2PMessage: (dto) => BaseApi.post(`/api/p2p/chats`, dto),
  setP2PMessageWasRead: (id) => BaseApi.put(`/api/p2p/chats/${id}/read`),
  setP2PMessagesWasRead: (ids) => {
    const messageIds = ids.map((id) => `ids=${id}`).join('&')
    BaseApi.put(`/api/p2p/chats/reads?${messageIds}`)
  },
  createP2PMessageWithFile: (multipartFormDataContent) =>
    BaseApi.post(`/api/p2p/chats/upload-file`, multipartFormDataContent),
  fetchP2PChatFile: (fileId) => BaseApi.get(`/api/p2p/chats/files/${fileId}`),
  fetchP2PMetadataChatFile: (fileId) => BaseApi.get(`/api/p2p/chats/files/${fileId}/metadata`),
  fetchP2PChatUsersInfo: (dealId) => BaseApi.get(`/api/p2p/chats/users-info?dealId=${dealId}`),

  //P2P appeal
  fetchP2PUserAppeals: (appealStatus = null, appealType = null) =>
    BaseApi.get(`/api/p2p/deal-appeals/all?appealStatus=${appealStatus ?? ''}&&appealType=${appealType ?? ''}`),
  createP2PAppeal: (model) => BaseApi.post(`/api/p2p/deal-appeals/`, model),
  cancelP2PAppeal: (dealId) => BaseApi.put(`/api/p2p/deals/${dealId}/appeal-status`),

  //P2P Deal Cancellation Dispute
  fetchP2PDealCancelDisputeByDealId: (dealId) => BaseApi.get(`/api/p2p/deals/${dealId}/cancel-dispute`),
  fetchP2PDealCancelDisputes: (status = null) =>
    BaseApi.get(`/api/p2p/deal-cancel-disputes?dealCancelDisputeStatus=${status}`),
  createP2PDealCancelDispute: (model) => BaseApi.post(`/api/p2p/deal-cancel-disputes/`, model),
  finishP2PDealCancelDispute: (dealId, status) =>
    BaseApi.put(`/api/p2p/deals/${dealId}/cancel-dispute/status?dealCancelDisputeStatus=${status}`),

  //Currency listing
  fetchCurrencyListings: (status = null) => BaseApi.get(`/api/currency-listings?status=${status}`),
  fetchCurrencyListing: (currencyCode) => BaseApi.get(`/api/currency-listings/${currencyCode}`),
  fetchCountOfTokenOwners: (currencyCode) => BaseApi.get(`/api/currency-listings/${currencyCode}/token-owners/count`),
  fetchTotalSupply: (currencyCode) => BaseApi.get(`/api/currency-listings/${currencyCode}/total-supply`),

  //Currency listing Comment
  fetchCurrencyListingComments: (currencyCode) => BaseApi.get(`/api/currency-listings/${currencyCode}/comments`),
  fetchCurrencyListingCommentsCount: (currencyCode) =>
    BaseApi.get(`/api/currency-listings/${currencyCode}/comments/count`),

  //Currency listing news
  fetchCurrencyListingNewsList: (currencyCode) => BaseApi.get(`/api/currency-listings/${currencyCode}/news`),
  fetchCurrencyListingNewsCount: (currencyCode) => BaseApi.get(`/api/currency-listings/${currencyCode}/news/count`),
  updateLikeForCurrencyListingNews: (id) => BaseApi.put(`/api/currency-listings/news/${id}/likes`),

  //Currency listing news comments
  fetchCurrencyListingNewsComment: (id) => BaseApi.get(`/api/currency-listings/news/${id}/comments`),
  fetchCurrencyListingNewsCommentCount: (id) => BaseApi.get(`/api/currency-listings/news/${id}/comments/count`),
  createCurrencyListingNewsComment: (id) => BaseApi.post(`/api/currency-listings/news/${id}/comments`),
  updateLikeForCurrencyListingNewsComment: (id) => BaseApi.put(`/api/currency-listings/news/comments/${id}/likes`),

  //2FA
  fetchGoogleAuthStatus: () => BaseApi.get(`/api/users/2fa/status`),
  fetchGoogle2FACode: (model) => BaseApi.post(`/api/users/2fa/generate-key`, model),
  updateGoogleAuthStatus: (model) => BaseApi.post(`/api/users/2fa/status`, model),
  sendCodeToEmail: () => BaseApi.post(`/api/users/2fa/send-email`),
  verifyGoogle2FA: (model) => BaseApi.post(`/api/users/2fa/verify`, model),
  validateEmailCode: (emailCode, config) => BaseApi.post(`/api/users/2fa/validate-email-code`, emailCode, config),

  //Email notifications
  fetchEmailNotificationSettings: () => BaseApi.get(`/api/p2p/email-notifications`),
  updateEmailNotificationSettings: (model) => BaseApi.post(`/api/p2p/email-notifications`, model),
}
