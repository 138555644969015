import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../auth/auth'

export const useVerificationCheck = () => {
  const { isUserVerified } = useAuth()
  const [isVerified, setIsVerified] = useState(null)

  useEffect(() => {
    if (isUserVerified === null) return
    setIsVerified(isUserVerified)
  }, [isUserVerified])

  const goToVerificationPage = () => {
    window.location.href = 'https://id.h2k.me/security'
  }

  return {
    goToVerificationPage,
    isVerified,
  }
}
