import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import arrow from './images/arrow.png'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
const navList = [
  {
    text: 'Assets',
    link: '/finance',
    submenu: [],
  },
  {
    text: 'PP',
    link: '/PP',
    submenu: [],
  },
]

export default function HeaderNavigation() {
  const { t } = useTranslation()
  const [navIndex, setNavIndex] = useState(false)
  return (
    <nav className='header__nav nav'>
      <ul className='nav__list'>
        {navList.map((item, i) => (
          <li
            className={`nav__item ${navIndex === i ? 'nav__item_open' : ''} ${
              item.link === '' ? 'nav__item_disabled' : ''
            }  `}
            key={i}
          >
            <NavLink to={`/${i18n.language}${item.link}`} className={'nav__link'} exact={true}>
              <span className='nav__text'>{t(item.text)}</span>
            </NavLink>
            {item.submenu.length ? (
              <React.Fragment>
                <span className='nav__action' onClick={() => (navIndex === i ? setNavIndex(false) : setNavIndex(i))}>
                  <img src={arrow} alt='image description' />
                </span>
                <ul className='nav__submenu submenu'>
                  {item.submenu.map((subItem, index) => (
                    <li className={`nav__item ${!subItem.link.length ? 'nav__item_disabled' : ''}`} key={index}>
                      <NavLink to={`/${i18n.language}${subItem.link}`} className='nav__link' exact={true}>
                        <span className='nav__text'>{t(subItem.text)}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  )
}
