import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { Stage1 } from './Stages/Stage1'
import { Stage2 } from './Stages/Stage2'
import { Stage3 } from './Stages/Stage3'
import React, { useEffect, useState } from 'react'
import api from '../../../api'
import ProgressSteps from './Stages/ProgressSteps'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { InitializeStageData, MapToCreateOrderModel } from './InitializeStageData'
import { toast } from 'react-toastify'
import { GetOpenOrderLimitKey, NextStageChecker, PostingStage } from './PostingData'
import NavigationButtons from './NavigationButtons'
import OrderPublishConfirmationModal from './Modals/OrderPublishConfirmationModal'
import PostingLimitsToastMessages from './PostingLimitsToastMessages'
import Google2FaModal from './Modals/Google2FaModal'
import { useVerificationCheck } from '../User/useVerificationCheck'
import useModalState from '../Progress/Modals/useModalState'
import PassVerificationModal from '../Home/PassVerificationModal'

export default function Posting({ userPaymentRequisites }) {
  const { t } = useTranslation()
  const history = useHistory()
  const { orderId } = useParams()
  const location = useLocation()
  const editOrder = location?.state?.order || null

  const { modals, openModal, closeModal } = useModalState()
  const { goToVerificationPage, isVerified } = useVerificationCheck()

  const [stageData, setStageData] = useState(null)
  const [nextButtonStageDisabled, setNextButtonStageDisabled] = useState({
    next: true,
    posting: true,
    limits: true,
    stage1: true,
    stage2: true,
    stage3: true,
  })
  const [isOrderConfirmationModalOpen, setIsOrderConfirmationModalOpen] = useState(false)

  const [allBalances, setAllBalances] = useState([])
  const [commissionsObj, setCommissionsObj] = useState(null)

  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(null)
  const [paymentRequisites, setPaymentRequisites] = useState(userPaymentRequisites)

  const functionBack = () => {
    handleDataFromStages({ currentStageIndex: stageData.currentStageIndex - 1 })
  }
  const functionNext = () => {
    if (!isVerified) {
      openModal('isPassVerificationModalOpen')
      return
    }
    handleDataFromStages({ currentStageIndex: stageData.currentStageIndex + 1 })
  }
  const fetchP2PRequisites = async () => {
    if (paymentRequisites) return
    try {
      const data = await api.fetchP2PRequisites()
      setPaymentRequisites(data || [])
    } catch (error) {
      console.error('Error fetching requisites:', error)
    }
  }

  useEffect(() => {
    if (editOrder) {
      setStageData(InitializeStageData(editOrder, t))
    } else {
      setStageData(InitializeStageData(null, t))
    }
  }, [orderId, editOrder])

  useEffect(() => {
    const savedStageData = window.stageData
    if (savedStageData && savedStageData !== 'null') {
      setStageData(InitializeStageData(null, JSON.parse(savedStageData), t))
      delete window.stageData
    }
  }, [])

  const handleDataFromStages = (data) => {
    let hasChanges = false
    let updatedData = {}

    Object.keys(data).forEach((key) => {
      if (data[key] !== stageData[key]) {
        updatedData[key] = data[key]
        hasChanges = true
      }
    })

    if (data.selectedSecondAsset && data.selectedSecondAsset !== stageData.selectedSecondAsset) {
      updatedData.selectedPaymentRequisites = null
      hasChanges = true
    }

    if (!hasChanges) return

    setStageData((prevStageData) => {
      const newState = {
        ...prevStageData,
        ...updatedData,
      }
      return newState
    })
  }

  const handleCreateOrder = async () => {
    handleNextButtonDisabled('limits', true)
    const orderData = MapToCreateOrderModel(stageData)
    try {
      editOrder ? await api.cloneP2POrder(editOrder.id, orderData) : await api.createP2POrder(orderData)
      toast.success(t('ActionWasSuccessful'))
      handleDataFromStages({
        userSelectedTradeRate: 0,
        amountOfSelectedFirstAsset: 0,
        amountOfSelectedSecondAsset: 0,
        selectedPaymentRequisites: [],
        currentStageIndex: PostingStage.STAGE_1_TYPE_AND_PRICE,
      })
      if (orderId || editOrder) history.push(`/${i18n.language}/pp/orders/`)
      else {
        fetchBalances()
        history.push(`/${i18n.language}/pp/posting/`)
      }
    } catch (error) {
      toast.error(t('ErrorTryLater'))
      handleDataFromStages({ currentStageIndex: PostingStage.STAGE_3_ADDITIONAL_CONDITIONS })
      window.stageData = null
    } finally {
      handleNextButtonDisabled('limits', false)
    }
  }
  const handleNextButtonDisabled = (key, value) => {
    setNextButtonStageDisabled((prev) => {
      if (prev[key] !== value) {
        return { ...prev, [key]: value }
      }
      return prev
    })
  }

  const handleSetSelectedBalances = () => {
    if (!allBalances?.balances?.length) {
      return
    }

    setStageData((prevStageData) => {
      const { selectedFirstAsset, selectedSecondAsset, selectedPair, currencyPairs } = prevStageData

      const findBalance = (currencyCode) =>
        allBalances.balances.find((balance) => balance.currencyCode === currencyCode)

      const getAvailableCurrencies = () => {
        const availablePairs =
          currencyPairs?.filter(
            (pair) => pair.currencyFrom.availableOnExchange || pair.currencyTo.availableOnExchange,
          ) || []

        return new Set(
          availablePairs.flatMap((pair) => [pair.currencyFrom?.code, pair.currencyTo?.code]).filter(Boolean),
        )
      }

      const availibleBalances = allBalances.balances
        .filter((balance) => getAvailableCurrencies().has(balance.currencyCode))
        .map(({ currencyCode, amount }) => ({
          currencyCode,
          amount,
        }))

      const createBalanceObject = (currencyCode, balance) => ({
        currencyCode,
        amount: balance?.amount ?? null,
      })

      const balanceFrom = createBalanceObject(
        selectedPair?.currencyFrom?.code,
        findBalance(selectedPair?.currencyFrom?.code),
      )
      const balanceTo = createBalanceObject(selectedPair?.currencyTo?.code, findBalance(selectedPair?.currencyTo?.code))

      const balanceOfFirstAsset = createBalanceObject(selectedFirstAsset, findBalance(selectedFirstAsset))
      const balanceOfSecondAsset = createBalanceObject(selectedSecondAsset, findBalance(selectedSecondAsset))

      return {
        ...prevStageData,
        availibleBalances,
        balanceOfFirstAsset,
        balanceOfSecondAsset,
        balanceOfAssetFrom: balanceFrom,
        balanceOfAssetTo: balanceTo,
      }
    })
  }

  const checkGoogle2FAEnabled = async () => {
    const isTwoFaEnabled = window.isTwoFaEnabled
    if (isTwoFaEnabled === true) return
    try {
      const result = await api.fetchGoogleAuthStatus()
      setIsTwoFaEnabled(result)
      window.isTwoFaEnabled = result
    } catch (error) {
      console.log('checkGoogle2FAEnabled error:', error)
    }
  }

  const saveStageDataToGlobal = () => {
    window.stageData = JSON.stringify(stageData)
  }
  const fetchCurrencies = async () => {
    try {
      const pairs = await api.fetchCurrencyPairs()

      const updatedStageData = {
        currencyPairs: pairs,
        ...(editOrder && {
          selectedPair: pairs.find((pair) => pair.code === editOrder.currencyPairCode),
        }),
      }

      setStageData((prevStageData) => ({
        ...prevStageData,
        ...updatedStageData,
      }))
    } catch (error) {
      console.error('Error loading currencies:', error)
    }
  }
  const fetchBalances = async () => {
    try {
      const balanceData = await api.fetchBalance()
      setAllBalances(balanceData)
    } catch (error) {
      console.error('Error fetching balance:', error)
    }
  }
  const fetchCommissions = async () => {
    try {
      const commissions = await api.fetchCommissions()
      setCommissionsObj(commissions)
    } catch (error) {
      console.error('Error commissions:', error)
    }
  }
  //init data
  useEffect(() => {
    fetchBalances()
    fetchCurrencies()
    fetchP2PRequisites()
    fetchCommissions()
    checkGoogle2FAEnabled()
  }, [])

  useEffect(() => {
    if (isVerified === false) {
      openModal('isPassVerificationModalOpen')
      return
    }
  }, [isVerified])

  useEffect(() => {
    handleSetSelectedBalances()
  }, [stageData?.selectedFirstAsset, stageData?.selectedSecondAsset, allBalances])

  useEffect(() => {
    if (!stageData) return

    if (stageData.currentStageIndex < PostingStage.STAGE_1_TYPE_AND_PRICE) {
      setStageData.currentStageIndex = PostingStage.STAGE_1_TYPE_AND_PRICE
    }
    if (stageData.currentStageIndex > PostingStage.STAGE_4_CONFIRM) {
      setStageData.currentStageIndex = PostingStage.STAGE_3_ADDITIONAL_CONDITIONS
    }
  }, [stageData?.currentStageIndex])

  useEffect(() => {
    if (!stageData) return
    const isDisabled = NextStageChecker(stageData?.currentStageIndex, nextButtonStageDisabled)
    handleNextButtonDisabled('next', isDisabled)
  }, [nextButtonStageDisabled, stageData?.currentStageIndex])

  if (!stageData) return null
  return (
    <React.Fragment>
      <div className='section'>
        <div className='section__main'>
          <div className='container'>
            <div className=' steps'>
              <div className='steps__main'>
                <ProgressSteps t={t} currentStageIndex={stageData.currentStageIndex} />
                {stageData.currentStageIndex === PostingStage.STAGE_1_TYPE_AND_PRICE && (
                  <Stage1
                    onStage1DataSubmit={handleDataFromStages}
                    onButtonNextDisabled={(value) => handleNextButtonDisabled('stage1', value)}
                    stageData={stageData}
                    commissionsObj={commissionsObj}
                  />
                )}
                {stageData.currentStageIndex === PostingStage.STAGE_2_PAYMENT_REQUISITES && (
                  <Stage2
                    onStage2DataSubmit={handleDataFromStages}
                    onButtonNextDisabled={(value) => handleNextButtonDisabled('stage2', value)}
                    stageData={stageData}
                    onSaveStageData={() => saveStageDataToGlobal()}
                    paymentRequisites={paymentRequisites}
                  />
                )}
                {stageData.currentStageIndex === PostingStage.STAGE_3_ADDITIONAL_CONDITIONS && (
                  <Stage3
                    onStage3DataSubmit={handleDataFromStages}
                    onButtonNextDisabled={(value) => handleNextButtonDisabled('stage3', value)}
                    stageData={stageData}
                  />
                )}

                {stageData.currentStageIndex !== PostingStage.STAGE_1_TYPE_AND_PRICE && (
                  <Google2FaModal
                    t={t}
                    opened={isTwoFaEnabled === false}
                    setOpened={() => {
                      handleDataFromStages({ currentStageIndex: PostingStage.STAGE_1_TYPE_AND_PRICE })
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <NavigationButtons
          t={t}
          onBackStage={functionBack}
          onNextStage={functionNext}
          showBackButton={true}
          showNextButton={stageData.currentStageIndex !== PostingStage.STAGE_3_ADDITIONAL_CONDITIONS}
          showSendButton={stageData.currentStageIndex === PostingStage.STAGE_3_ADDITIONAL_CONDITIONS}
          isBackButtonDisabled={stageData.currentStageIndex === 0}
          isNextButtonDisabled={nextButtonStageDisabled.next}
          isSendButtonDisabled={nextButtonStageDisabled.limits || nextButtonStageDisabled.next}
          onSend={() => {
            setIsOrderConfirmationModalOpen(true)
          }}
        />
      </div>
      <OrderPublishConfirmationModal
        opened={isOrderConfirmationModalOpen}
        setOpened={setIsOrderConfirmationModalOpen}
        data={stageData}
        onOrderPublishConfirmed={() => {
          setIsOrderConfirmationModalOpen(false)
          handleCreateOrder()
        }}
      />
      <PostingLimitsToastMessages
        t={t}
        onButtonNextDisabledLimits={(value) => handleNextButtonDisabled('limits', value)}
        stageData={stageData}
        editOrder={editOrder}
      />
      <PassVerificationModal
        opened={modals.isPassVerificationModalOpen}
        onClose={() => closeModal('isPassVerificationModalOpen')}
        onConfirmPassVerification={goToVerificationPage}
      />
    </React.Fragment>
  )
}
