import api from 'api'
import ProgressIcons from '../ProgressIcons'
import React, { useEffect, useState } from 'react'
import AddUserToBlackListModal from '../Modals/AddUserToBlackListModal'

const ChatHeader = ({ t, deal, messages }) => {
  const { isDealCreator, order } = deal
  const [verificationOpponentName, setVerificationOpponentName] = useState('Username not specified')
  const userNicknameOpponentName = isDealCreator ? order?.userNickname : deal?.userNickname
  const [comment, setComment] = useState('')
  const [isUserBlocked, setIsUserBlocked] = useState(false)
  const blockedUserId = isDealCreator ? deal?.order?.userId : deal?.dealUserId

  const [isOpenModal, setIsOpenModal] = useState(false)

  async function getUsersInfo(dealId) {
    try {
      const usersInfo = await api.fetchP2PChatUsersInfo(dealId)
      const verificationOpponentName = isDealCreator
        ? usersInfo.orderCreatorVerificationName
        : usersInfo.dealCreatorVerificationName

      if (verificationOpponentName?.trim()) {
        setVerificationOpponentName(verificationOpponentName)
      }
    } catch (error) {
      console.error('Error loading chat users info', error)
    }
  }

  async function fetchP2PUserBlockList() {
    try {
      const blockedUsers = await api.fetchP2PUserBlockList(blockedUserId)
      if (blockedUsers.length && blockedUsers.some((user) => user.blockedUserId === blockedUserId))
        setIsUserBlocked(true)
    } catch (error) {
      console.error('Error fetchP2PUserBlockList', error)
    }
  }

  const handleAddToBlackList = async () => {
    const userBlockCreateDto = {
      blockedUserId,
      comment,
    }
    try {
      await api.addP2PUserBlock(userBlockCreateDto)
      setIsUserBlocked(true)
      setIsOpenModal(false)
    } catch (error) {
      console.error('Error addP2PUserBlock', error)
    }
  }

  useEffect(() => {
    if (deal) {
      getUsersInfo(deal.id)
      fetchP2PUserBlockList()
    }
  }, [])

  return (
    <React.Fragment>
      <div className='chat__header'>
        <div className='chat__avatar avatar'>
          <span className='avatar__text'>{userNicknameOpponentName?.charAt(0) || 'u'}</span>
        </div>
        <div className='chat__title'>
          <span className='chat__text'>{userNicknameOpponentName}</span>
          <button className='btn btn__small' onClick={() => setIsOpenModal(true)} disabled={isUserBlocked}>
            {t(`${isUserBlocked ? 'Blocked' : 'AddToBlackList'}`)}
          </button>
        </div>
        <div className='chat__subtitle'>
          <span className='chat__text'>Verified: {verificationOpponentName} </span>
        </div>
      </div>
      <AddUserToBlackListModal
        opened={isOpenModal}
        onClose={setIsOpenModal}
        onConfirmBlock={handleAddToBlackList}
        comment={comment}
        setComment={setComment}
      />
    </React.Fragment>
  )
}

export default ChatHeader
