import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import { findCommission } from 'helpers/helper'
import CommissionTypes from 'data/CommissionTypes'
import {
  BuySellTabIndex,
  DigitType,
  GetCommissionAmount,
  GetRoundedValue,
  HandleExchangeAssetChange,
  HandlePaymentAssetChange as HandlePaymentAssetChange,
} from '../PostingData'

export function Stage1({ onStage1DataSubmit, onButtonNextDisabled, stageData, commissionsObj }) {
  const { t } = useTranslation()
  const [selectedFirstAsset, setSelectedFirstAsset] = useState(stageData.selectedFirstAsset)
  const [selectedSecondAsset, setSelectedSecondAsset] = useState(stageData.selectedSecondAsset)
  const [selectedPair, setSelectedPair] = useState(stageData.selectedPair)
  const [selectedBuySellTabIndex, setSelectedBuySellTabIndex] = useState(stageData.selectedBuySellTabIndex)
  const [userSelectedTradeRate, setUserSelectedTradeRate] = useState(stageData.userSelectedTradeRate)
  const [amountOfSelectedFirstAsset, setAmountOfSelectedFirstAsset] = useState(stageData.amountOfSelectedFirstAsset)
  const [amountOfSelectedSecondAsset, setAmountOfSelectedSecondAsset] = useState(stageData.amountOfSelectedSecondAsset)
  const [isInputValueChanged, setIsInputValueChanged] = useState(false)

  const [commission, setCommission] = useState(stageData.commission)
  const [totalAmountWithCommission, setTotalAmountWithCommission] = useState(stageData.totalAmountWithCommission)
  const [commissionAmount, setCommissionAmount] = useState(null)

  const [isBuying, setIsBuying] = useState(stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy)

  const handleFocus = (event) => {
    event.target.dataset.initialValue = event.target.value
  }

  const handleBlur = (event) => {
    if (event.target.dataset.initialValue !== event.target.value) {
      setIsInputValueChanged(true)
    }
    delete event.target.dataset.initialValue
  }

  const shouldDisableNextButton = () => {
    if (!selectedPair || !selectedFirstAsset || !selectedSecondAsset) {
      return true
    }
    const shouldCheckBalanceOfFromAsset = !isBuying && selectedPair?.currencyFrom?.availableOnExchange
    const shouldCheckBalanceOfToAsset = isBuying && selectedPair?.currencyTo?.availableOnExchange
    const amountOfFromAsset = parseFloat(getAmountOfSelectedAsset(selectedPair.currencyFrom.code))
    const amountOfToAsset = parseFloat(getAmountOfSelectedAsset(selectedPair.currencyTo.code))
    const balanceOfFromAsset = parseFloat(stageData.balanceOfAssetFrom?.amount)
    const balanceOfToAsset = parseFloat(stageData.balanceOfAssetTo?.amount)

    const validationChecks = {
      isTradeRateInvalid: parseFloat(userSelectedTradeRate) <= 0,
      isExchangeAmountInvalid: amountOfFromAsset <= 0,
      isPaymentAmountInvalid: amountOfToAsset <= 0,
      isPaymentAssetInvalid: !selectedSecondAsset,
      isBalanceNotAvailable:
        (shouldCheckBalanceOfFromAsset && balanceOfFromAsset === null) ||
        (shouldCheckBalanceOfToAsset && balanceOfToAsset === null),
      isAmountExceedsBalance:
        (shouldCheckBalanceOfFromAsset && amountOfFromAsset > balanceOfFromAsset) ||
        (shouldCheckBalanceOfToAsset && amountOfToAsset > balanceOfToAsset),
      isCommissionInvalid: isBuying && (!totalAmountWithCommission || parseFloat(totalAmountWithCommission) <= 0),
      isSumWithCommExceedsBalance: !isBuying && parseFloat(totalAmountWithCommission) > balanceOfFromAsset,
    }

    return Object.values(validationChecks).some((check) => check === true)
  }

  const formDataToSubmit = () => {
    const dataToSubmit = {
      // currentStageIndex,
      selectedFirstAsset,
      selectedSecondAsset,
      selectedPair,
      selectedBuySellTabIndex,
      userSelectedTradeRate,
      amountOfSelectedFirstAsset,
      amountOfSelectedSecondAsset,
      commission,
      totalAmountWithCommission,
    }
    onStage1DataSubmit(dataToSubmit)
  }

  const getAmountOfSelectedAsset = (assetCode) => {
    if (!amountOfSelectedFirstAsset || !amountOfSelectedSecondAsset) return ''
    return selectedPair?.currencyFrom?.code === assetCode ? amountOfSelectedFirstAsset : amountOfSelectedSecondAsset
  }

  const getCommissionAmount = () => {
    if (!commission || !selectedPair) {
      setCommissionAmount('')
      return { amount: null, commAmount: null }
    }
    const amount = getAmountOfSelectedAsset(selectedPair?.currencyFrom?.code)
    const commAmount = GetCommissionAmount(amount, commission)
    setCommissionAmount(parseFloat(commAmount.toFixed(selectedPair?.digitsAmountP2P || 4)).toString())
    return { amount, commAmount }
  }

  const getTotalAmountWithCommission = () => {
    const { amount, commAmount } = getCommissionAmount()
    if (!amount || !commAmount || isNaN(amount) || isNaN(commAmount)) {
      setTotalAmountWithCommission('')
      return
    }
    const finalAmount = isBuying ? parseFloat(amount) - commAmount : parseFloat(amount) + commAmount
    setTotalAmountWithCommission(parseFloat(finalAmount.toFixed(selectedPair?.digitsAmountP2P || 4)).toString())
  }

  const handleAmountOfFirstAssetChange = (value) => {
    const roundedValue = HandleExchangeAssetChange(value, stageData)
    setAmountOfSelectedFirstAsset(roundedValue)

    var roundedSecondAssetAmount = HandlePaymentAssetChange(roundedValue * userSelectedTradeRate, stageData)
    const formattedAmount = roundedSecondAssetAmount?.toString() || '0'
    setAmountOfSelectedSecondAsset(formattedAmount)
  }

  const handleAmountOfSecondAssetChange = (value) => {
    var roundedValue = HandlePaymentAssetChange(value, stageData)
    setAmountOfSelectedSecondAsset(roundedValue)

    var roundedFirstAssetAmount = HandleExchangeAssetChange(roundedValue / userSelectedTradeRate, stageData)
    const formattedAmount = roundedFirstAssetAmount?.toString() || '0'
    setAmountOfSelectedFirstAsset(formattedAmount)
  }

  useEffect(() => {
    formDataToSubmit()
  }, [
    stageData.selectedBuySellTabIndex,
    selectedFirstAsset,
    selectedSecondAsset,
    selectedBuySellTabIndex,
    selectedPair,
    commission,
    totalAmountWithCommission,
    userSelectedTradeRate,
  ])

  useEffect(() => {
    if (!isInputValueChanged) return
    formDataToSubmit()
    setIsInputValueChanged(false)
  }, [isInputValueChanged])

  useEffect(() => {
    onButtonNextDisabled(shouldDisableNextButton())
  }, [
    stageData.selectedBuySellTabIndex,
    selectedFirstAsset,
    selectedSecondAsset,
    amountOfSelectedFirstAsset,
    amountOfSelectedSecondAsset,
    userSelectedTradeRate,
    stageData.availibleBalances,
    selectedPair,
    totalAmountWithCommission,
  ])

  useEffect(() => {
    if (commissionsObj == null || !selectedPair) return
    const type = isBuying ? CommissionTypes.P2pBuy : CommissionTypes.P2pSell
    const comm = findCommission(commissionsObj.commissions, type, stageData?.selectedPair?.code)
    setCommission(comm)
  }, [commissionsObj, selectedBuySellTabIndex, selectedFirstAsset, selectedSecondAsset, stageData?.selectedPair])

  useEffect(() => {
    if (!selectedFirstAsset || !selectedSecondAsset || !selectedPair) return
    HandleExchangeAssetChange(amountOfSelectedFirstAsset, stageData)
    HandlePaymentAssetChange(amountOfSelectedSecondAsset, stageData)
    setUserSelectedTradeRate(GetRoundedValue(userSelectedTradeRate, DigitType.Price, stageData))
  }, [selectedFirstAsset, selectedSecondAsset, selectedPair])

  useEffect(() => {
    if (!selectedFirstAsset || !selectedSecondAsset || !stageData.currencyPairs?.length) return

    const pairs = {
      buy: stageData.currencyPairs.find((pair) => pair.code === `${selectedSecondAsset}_${selectedFirstAsset}`),
      sell: stageData.currencyPairs.find((pair) => pair.code === `${selectedFirstAsset}_${selectedSecondAsset}`),
    }

    if (pairs.buy) {
      setSelectedBuySellTabIndex(BuySellTabIndex.Buy)
      setSelectedPair(pairs.buy)
      setIsBuying(true)
    } else if (pairs.sell) {
      setSelectedBuySellTabIndex(BuySellTabIndex.Sell)
      setSelectedPair(pairs.sell)
      setIsBuying(false)
    }
  }, [selectedFirstAsset, selectedSecondAsset])

  useEffect(() => {
    getTotalAmountWithCommission()
  }, [
    commission,
    selectedFirstAsset,
    selectedSecondAsset,
    selectedPair,
    amountOfSelectedFirstAsset,
    amountOfSelectedSecondAsset,
  ])

  useEffect(() => {
    if (stageData.selectedPair && !selectedPair) {
      setSelectedPair(stageData.selectedPair)
    }
    if (stageData.commission && !commission) {
      setCommission(stageData.commission)
    }
  }, [stageData.selectedPair, stageData.commission])

  return (
    <React.Fragment>
      <div className='steps__main'>
        <div className=' tabs'>
          {/* <BuySellTabsHeader
            t={t}
            selectedBuySellTabIndex={selectedBuySellTabIndex}
            setSelectedBuySellTabIndex={setSelectedBuySellTabIndex}
            isEnableSelection={stageData.isEnableSelection}
          /> */}
          <div className=' tabs__main'>
            <div className=' tabs__list'>
              <div className=' tabs__item'>
                <div className='wrapper'>
                  <div className='group'>
                    <div className='group__main'>
                      <div className='formular'>
                        <SelectedFirstAsset
                          t={t}
                          selectedFirstAsset={selectedFirstAsset}
                          setSelectedFirstAsset={setSelectedFirstAsset}
                          selectedSecondAsset={selectedSecondAsset}
                          setSelectedSecondAsset={setSelectedSecondAsset}
                          isBuying={isBuying}
                          setSelectedPair={setSelectedPair}
                          stageData={stageData}
                        />
                        <SelectedSecondAsset
                          t={t}
                          selectedFirstAsset={selectedFirstAsset}
                          setSelectedFirstAsset={setSelectedFirstAsset}
                          selectedSecondAsset={selectedSecondAsset}
                          setSelectedSecondAsset={setSelectedSecondAsset}
                          isBuying={isBuying}
                          setSelectedPair={setSelectedPair}
                          stageData={stageData}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='group'>
                    <div className='group__main'>
                      <div className='formular'>
                        <div className='row'>
                          <div className='col col_4 col_desktop-6 col_tab-8 col_mob-12'>
                            <div className='row'>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__header'>
                                    <span className='form-item__title'>{t('YourCourse')}</span>
                                  </div>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        // after={stageData.selectedPair?.currencyFrom.code}
                                        value={userSelectedTradeRate}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          const rate = GetRoundedValue(value, DigitType.Price, stageData)
                                          setUserSelectedTradeRate(rate)
                                          var roundedOtcAssetAmount = HandlePaymentAssetChange(
                                            rate * amountOfSelectedFirstAsset,
                                            stageData,
                                          )
                                          setAmountOfSelectedSecondAsset(roundedOtcAssetAmount)
                                        }}
                                        addClass
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col col_4 col_desktop-6 col_tab-8 col_mob-12'>
                            <div className='row'>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedPair?.currencyFrom?.code}
                                        value={amountOfSelectedFirstAsset}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          handleAmountOfFirstAssetChange(value)
                                        }}
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedPair?.currencyTo?.code}
                                        value={amountOfSelectedSecondAsset}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          handleAmountOfSecondAssetChange(value)
                                        }}
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col col_8 col_desktop-8 col_tab-8 col_mob-8'>
                                <div className='form-item'>
                                  <div className='form-item__footer'>
                                    <div className='form-item__data data'>
                                      <div className='data__item data__item_before'>
                                        <span className='data__text'>
                                          {t('Commission')}{' '}
                                          {selectedPair?.currencyFrom?.code && commission
                                            ? `${commission?.percent}% (${t('Min')}. ${commission?.minimum} ${
                                                selectedPair?.currencyFrom?.code
                                              })`
                                            : ''}
                                        </span>
                                      </div>
                                      <div className='data__item data__item_after'>
                                        <span className='data__text'>
                                          {commissionAmount || ''}{' '}
                                          {selectedPair?.currencyFrom?.code || `${t('Loading')}`}
                                        </span>
                                      </div>
                                    </div>

                                    <div className='form-item__data data'>
                                      <div className='data__item data__item_before'>
                                        <span className='data__text' style={{ whiteSpace: 'nowrap' }}>
                                          {isBuying
                                            ? t('P2POrderAfterSubtractedCommission')
                                            : t('P2POrderAfterAddedCommission')}
                                          :
                                        </span>
                                      </div>
                                      <div className='data__item data__item_after'>
                                        <span className='data__text'>
                                          {totalAmountWithCommission
                                            ? `${totalAmountWithCommission} ${selectedPair?.currencyFrom?.code}`
                                            : `${t('Loading')}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const BuySellTabsHeader = ({ t, selectedBuySellTabIndex, setSelectedBuySellTabIndex, isEnableSelection }) => {
  const buySellTabs = ['WantToBuy', 'WantToSell']

  return (
    <div className=' tabs__header'>
      <div className='row'>
        <div className='col col_5 col_mob-8'>
          <div className=' tabs__list'>
            {buySellTabs.map((element, index) => {
              return (
                <button
                  className={`tabs__item w-100  ${
                    element === buySellTabs[selectedBuySellTabIndex] && 'tabs__item_active'
                  } ${element === 'WantToBuy' && 'tabs__item_buy'} ${element === 'WantToSell' && 'tabs__item_sell'}`}
                  onClick={() => setSelectedBuySellTabIndex(index)}
                  key={index}
                  disabled={!isEnableSelection}
                >
                  <span className='tabs__title'>{t(element)}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const SEPARATOR = '───'

const SelectedFirstAsset = ({
  t,
  selectedFirstAsset,
  setSelectedFirstAsset,
  selectedSecondAsset,
  setSelectedSecondAsset,
  isBuying,
  setSelectedPair,
  stageData,
}) => {
  const getFilteredAssets = () => {
    if (stageData.currencyPairs.Length === 0) return ['Loading']

    if (!stageData.isEnableSelection) {
      return [selectedFirstAsset]
    }

    const cryptoAssets = []
    const fiatAssets = []

    stageData.currencyPairs.forEach((pair) => {
      const processAsset = (asset) => {
        const target = asset.availableOnExchange ? cryptoAssets : fiatAssets
        if (!target.includes(asset.code)) {
          target.push(asset.code)
        }
      }
      processAsset(pair.currencyFrom)
      processAsset(pair.currencyTo)
    })

    cryptoAssets.sort()
    fiatAssets.sort()

    return [`${SEPARATOR}DigitalAssets${SEPARATOR}`, ...cryptoAssets, `${SEPARATOR}Fiat${SEPARATOR}`, ...fiatAssets]
  }

  const firstAssets = getFilteredAssets()

  const checkAvailiblePair = (value) => {
    const result = stageData.currencyPairs.some(
      (pair) => pair.code === `${selectedSecondAsset}_${value}` || pair.code === `${value}_${selectedSecondAsset}`,
    )
    return result
  }

  return (
    <div className='row'>
      <div className='col col_2 col_desktop-3 col_tab-4 col_mob-6'>
        <div className='form-item'>
          <div className='form-item__header'>
            <span className='form-item__title'>{t('ReceiveActive')}</span>
          </div>
          <div className='form-item__main'>
            <div className='form-item__field'>
              <Select
                list={firstAssets}
                value={selectedFirstAsset}
                addClasses={['w-100']}
                filter={true}
                image={true}
                multiple={false}
                selectCallback={(value) => {
                  if (value.startsWith(SEPARATOR) || value.endsWith(SEPARATOR)) return
                  setSelectedFirstAsset(value)
                  if (value === selectedSecondAsset || !checkAvailiblePair(value)) {
                    setSelectedSecondAsset(null)
                    setSelectedPair(null)
                  }
                }}
                disabled={!stageData.isEnableSelection}
                separator={SEPARATOR}
              />
            </div>
          </div>
          {stageData?.balanceOfFirstAsset?.amount && (
            <div className='form-item__data data'>
              <div className='data__item data__item_before'>
                <span className='data__text'>{t('Balance')}:</span>
              </div>
              <div className='data__item data__item_after'>
                <span className='data__text'>
                  {stageData?.balanceOfFirstAsset?.amount} {stageData?.balanceOfFirstAsset?.currencyCode}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SelectedSecondAsset = ({
  t,
  selectedFirstAsset,
  setSelectedFirstAsset,
  selectedSecondAsset,
  setSelectedSecondAsset,
  isBuying,
  setSelectedPair,
  stageData,
}) => {
  const getFilteredAssets = () => {
    if (stageData.currencyPairs.Length === 0) return ['Loading']

    if (!stageData.isEnableSelection) {
      return [selectedSecondAsset]
    }

    const cryptoAssets = []
    const fiatAssets = []

    stageData.currencyPairs.forEach((pair) => {
      const processAsset = (asset) => {
        const target = asset.availableOnExchange ? cryptoAssets : fiatAssets
        if (!target.includes(asset.code)) {
          target.push(asset.code)
        }
      }
      processAsset(pair.currencyFrom)
      processAsset(pair.currencyTo)
    })
    cryptoAssets.sort()
    fiatAssets.sort()

    return [`${SEPARATOR}DigitalAssets${SEPARATOR}`, ...cryptoAssets, `${SEPARATOR}Fiat${SEPARATOR}`, ...fiatAssets]
  }

  const secondAssets = getFilteredAssets()

  const checkAvailiblePair = (value) => {
    const result = stageData.currencyPairs.some(
      (pair) => pair.code === `${value}_${selectedFirstAsset}` || pair.code === `${selectedFirstAsset}_${value}`,
    )
    return result
  }

  return (
    <div className='row'>
      <div className='col col_2  col_desktop-3 col_tab-4 col_mob-6'>
        <div className='form-item'>
          <div className='form-item__header'>
            <span className='form-item__title'>{t('GivesActive')}</span>
          </div>
          <div className='form-item__main'>
            <div className='form-item__field'>
              <Select
                list={secondAssets}
                value={selectedSecondAsset}
                addClasses={['w-100']}
                filter={true}
                image={true}
                multiple={false}
                selectCallback={(value) => {
                  if (value.startsWith(SEPARATOR) || value.endsWith(SEPARATOR)) return
                  setSelectedSecondAsset(value)
                  if (value === selectedFirstAsset || !checkAvailiblePair(value)) {
                    setSelectedFirstAsset(null)
                    setSelectedPair(null)
                  }
                }}
                disabled={!stageData.isEnableSelection}
                separator={SEPARATOR}
              />
            </div>
          </div>
          {stageData?.balanceOfSecondAsset?.amount && (
            <div className='form-item__data data'>
              <div className='data__item data__item_before'>
                <span className='data__text'>{t('Balance')}:</span>
              </div>
              <div className='data__item data__item_after'>
                <span className='data__text'>
                  {stageData?.balanceOfSecondAsset?.amount} {stageData?.balanceOfSecondAsset?.currencyCode}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
